<alert *ngIf="!(isLoggedIn() | async)"></alert>
<div class="mx-auto h-100 row justify-content-center" *ngIf="!(isLoggedIn() | async)">
  <div class="card rounded-0 align-self-center col-4">
    <div class="card-header">
      <h3 class="mb-0">Login</h3>
    </div>
    <div class="card-body">
      <form class="form" role="form" autocomplete="off" id="formLogin" novalidate="" method="POST"
        (ngSubmit)="f.form.valid && login()" #f="ngForm" name="loginForm" role="form">
        <div class="mb-3">
          <label for="uname1">Benutzername</label>
          <input type="text" class="form-control form-control-lg rounded-0" name="username" [(ngModel)]="model.username"
            #username="ngModel" required>
          <div class="invalid-feedback">Oops, you missed this one.</div>
        </div>
        <div class="mb-3">
          <label>Passwort</label>
          <input type="password" class="form-control form-control-lg rounded-0" name="password"
            [(ngModel)]="model.password" #password="ngModel" required>
          <div class="invalid-feedback">Enter your password too!</div>
        </div>
        <button type="submit" class="btn btn-lg float-end" id="btnLogin" [disabled]="loading">Login</button>
      </form>
    </div>
  </div>
</div>