import { Component, OnInit, OnDestroy } from '@angular/core';
 
import { AlertService } from '../../_services/alert.service';
 
@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})
 
export class AlertComponent {
    message: any;
 
    constructor(private alertService: AlertService) { }

    ngOnInit() {
        console.info('AlertComponent ngOnInit()');
        this.alertService.getMessage().subscribe(message => { this.message = message; });
    }

    ngOnDestroy(){
        console.info('AlertComponent ngOnDestroy()');
    }

    clearAlert() {
        this.alertService.clearAlert();
    }
}