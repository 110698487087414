import { Component, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../_services/data.service';
import { EditableComponent } from './../editable/editable.component';
import { Category } from '../../_models/category';
import { Group } from '../../_models/group';

@Component({
    templateUrl: 'categorys.component.html'
})

export class CategorysComponent extends EditableComponent implements OnInit {

    datatypeName = "Kategorie";
    categorys: any[];
    groups: any[];

    constructor(protected dataService: DataService, protected modalService: NgbModal) {
        super(modalService);
    }

    ngOnInit() {
        console.info('CategorysComponent ngOnInit()');
        this.groups =  Array.from(this.dataService.groups.values());
        this.categorys = this.updateAllData();
    }

    openNewDialog(){
        super.openCreateDialog(new Category);
    }

    updateAllData() {
        // load all categorys
        let categorys = Array.from(this.dataService.categorys.values());
        // remove deleted
        categorys = categorys.filter(category => !category.deleted);
        // sort by group name and name
        categorys.sort((a, b) => {
            let agroup = this.dataService.getGroupForId(a.group).name;
            let bgroup = this.dataService.getGroupForId(b.group).name;
            let aname = a.name;
            let bname = b.name;
            return (agroup < bgroup) ? -1 : (agroup > bgroup) ? 1 : (aname < bname) ? -1 : (aname > bname) ? 1 : 0;
        });
        // set locally categorys
        return categorys;
    }

    editData(){
        let tempCategory = JSON.parse(JSON.stringify(this.currentData));
        tempCategory.group = parseInt(tempCategory.group);
        if (tempCategory.id != null) {
            this.dataService.editCategory(tempCategory).then(result => {
                this.categorys = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        } else {
            this.dataService.addCategory(tempCategory).then(result => {
                this.categorys = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        }
    }

    deleteData(){
        this.dataService.deleteCategory(this.currentData).then((result) => {
            this.categorys = this.updateAllData();
            this.closeDialog();
        }, error => {
            this.error = error;
        });
    }
}