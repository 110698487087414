<div class="row">
  <div class="col align-self-center">
    <button type="button" class="btn btn-secondary btn-sm fa fa-plus float-left"
      (click)="openNewTransactionDialog()"></button>
  </div>
  <div class="col offset-3 align-self-center">
    <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-left float-end"
      (click)="goToPreviousMonth()"></button>
  </div>
  <input class="col form-control" type="text" placeholder="Startdatum" [(ngModel)]="dateStart"
    (ngModelChange)="filterChanged(dateStart, dateEnd, $event, filtertext)" name="dateStart" />
  <input class="col form-control" type="text" placeholder="Enddatum" [(ngModel)]="dateEnd"
    (ngModelChange)="filterChanged(dateStart, dateEnd, $event, filtertext)" name="dateEnd" />
  <div class="col align-self-center">
    <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-up float-left"
      (click)="goToCurrentMonth()"></button>
    <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-right float-left"
      (click)="goToNextMonth()"></button>
    <button type="button" class="btn btn-secondary btn-sm fa fa-history float-left"
      (click)="filterChanged(null, null, null, filtertext)"></button>
  </div>
  <input class="col form-control" type="text" placeholder="Filtertext" [(ngModel)]="filtertext"
    (ngModelChange)="filterChanged(dateStart, dateEnd, $event, filtertext)" name="filtertext" style="width: 50%" />
  <div class="col align-self-center">
    <button type="button" class="btn btn-secondary btn-sm fa fa-history float-left"
      (click)="filterChanged(dateStart, dateEnd, null, '')"></button>
    <button type="button" class="btn btn-secondary btn-sm fa fa-bar-chart float-left"
      (click)="statisticChanged()"></button>
  </div>
</div>
<div class="row">
  <div class="col-4 offset-4" *ngIf="showStatistics">
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th class="text-center">Geldfluß</th>
          <th class="text-end">Betrag</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center">Anzahl</td>
          <td class="text-end">{{transactionsLength}}</td>
        </tr>
        <tr app-amount [amount]="transactionsIncome">
        <tr app-amount [amount]="transactionsOutcome">
        <tr style="border-top-style: groove;" app-amount [amount]="transactionsSum">
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <table class="table-responsive">
    <table class="table table-sm table-striped table-condensed">
      <thead>
        <tr>
          <th class="text-center">Datum</th>
          <th class="text-center">Quelle</th>
          <th class="text-center">Ziel</th>
          <th class="text-center">Kategorie</th>
          <th class="text-center">Betrag</th>
          <th class="text-center"></th>
          <th class="text-center">Tags</th>
          <th class="text-center">Beschreibung</th>
          <th class="text-center" [width]="240"></th>
        </tr>
      </thead>
      <tbody infinite-scroll (scrolled)="onScrollDown()">
        <ng-container *ngFor="let transaction of transactions">
          <tr
            [ngClass]="{latestElement: transaction.id == latestTransactionId, inactiveElement:transaction.active==false}"
            (mouseover)="showActionButtons[transaction.id] = true"
            (mouseleave)="showActionButtons[transaction.id] = false">
            <td class="text-center text-nowrap">{{ transaction.date | date : 'EEE dd.MM.yy'}}</td>
            <td class="text-center text-nowrap"
              (click)="filterChanged(dateStart, dateEnd, $event, dataService.getAccountForId(transaction.source).name)">
              {{
              dataService.getAccountForId(transaction.source).name }}</td>
            <td class="text-center text-nowrap"
              (click)="filterChanged(dateStart, dateEnd, $event, dataService.getAccountForId(transaction.target).name)">
              {{
              dataService.getAccountForId(transaction.target).name }}</td>
            <td class="text-center text-nowrap"
              (click)="filterChanged(dateStart, dateEnd, $event, dataService.getCategoryForId(transaction.category).name)">
              {{ dataService.getCategoryForId(transaction.category).name }}</td>
            <td class="text-center text-nowrap" [ngClass]="styleService.getStyleForTransaction(transaction)">{{
              transaction.value/100 | currency:'EUR': 'symbol'}}</td>
            <td class="text-center text-nowrap" style="vertical-align: middle;">
              <div class="progress text-center align-items-center justify-content-center"
                style="width: 40px; border: 1px solid rgba(0,0,0,.125)" *ngIf="transaction.share"
                (click)="filterChanged(dateStart, dateEnd, $event, dataService.getPersonForId(transaction.share.person).name)"
                data-toggle="tooltip"
                title="{{this.authenticationService.getUsernameFromToken()}}: {{this.getOwnValueAbsolute(transaction)/100 | currency:'EUR':'symbol'}}&#10;{{dataService.getPersonForId(transaction.share.person).name }}: {{transaction.share.value/100 | currency:'EUR':'symbol'}}">
                <div style="position:absolute;text-align:center;background-color: transparent">
                  {{this.getOwnValuePercent(transaction)}}%</div>
              </div>
            </td>
            <td class="text-center text-nowrap">
              <ng-container *ngFor="let tag of transaction.tags">
                <span class="badge bg-secondary"
                  (click)="filterChanged(dateStart, dateEnd, $event, dataService.getTagForId(tag).name)">{{dataService.getTagForId(tag).name}}</span>
              </ng-container>
            </td>
            <td class="text-center" (click)="filterChanged(dateStart, dateEnd, $event, transaction.description)">{{
              transaction.description }}</td>
            <td class="vcenter">
              <button type="button"
                [ngClass]="{invisible: !showActionButtons[transaction.id], 'fa-check': !transaction.active, 'fa-check-square-o': transaction.active}"
                class="btn btn-secondary btn-sm fa" (click)="toggleActive(transaction)">
              </button>
              <button type="button" [ngClass]="{invisible: !showActionButtons[transaction.id]}"
                class="btn btn-secondary btn-sm fa fa-chevron-up" (click)="moveTransaction(transaction, 'UP')">
              </button>
              <button type="button" [ngClass]="{invisible: !showActionButtons[transaction.id]}"
                class="btn btn-secondary btn-sm fa fa-chevron-down" (click)="moveTransaction(transaction, 'DOWN')">
              </button>
              <button type="button" [ngClass]="{invisible: !showActionButtons[transaction.id]}"
                class="btn btn-secondary btn-sm fa fa-times" (click)="openDeleteTransactionDialog(transaction)">
              </button>
              <button type="button" [ngClass]="{invisible: !showActionButtons[transaction.id]}"
                class="btn btn-secondary btn-sm fa fa-pencil-square-o" (click)="openEditTransactionDialog(transaction)">
              </button>
              <button type="button" [ngClass]="{invisible: !showActionButtons[transaction.id]}"
                class="btn btn-secondary btn-sm fa fa-files-o" (click)="openCopyTransactionDialog(transaction)">
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </table>
</div>
<ng-template #deleteDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">Lösche Buchung!</h3>
  </div>
  <div class="modal-body">
    <h5 class="modal-title">Soll die Buchung "{{currentTransaction.description}}" wirklich gelöscht werden?</h5>
    <div *ngIf="error" class="alert alert-danger"><span>{{error.message}}</span></div>
  </div>
  <div class="modal-footer">
    <button (click)="deleteTransaction()" class="btn btn-secondary">Löschen</button>
    <button (click)="closeDialog()" class="btn btn-secondary">Abbrechen</button>
  </div>
</ng-template>
<ng-template #handleDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <form>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Aktiv</label>
        <div class="col-10 align-self-center">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="currentTransaction.active" name="active" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Datum</label>
        <div class="col">
          <input class="form-control" type="datetime-local"
            [ngModel]="currentTransaction.date | date:'yyyy-MM-ddTHH:mm'" name="date"
            (ngModelChange)="parseDate($event)" />
        </div>
        <div class="col align-self-center">
          <button type="button" class="btn btn-secondary btn-sm fa fa-caret-left"
                  (click)="dateToStartOfCurrentMonth()"></button>
          <button type="button" class="btn btn-secondary btn-sm fa fa-caret-right"
                  (click)="dateToEndOfCurrentMonth()"></button>
          <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-left"
                  (click)="dateToStartOfNextMonth()"></button>
          <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-right"
                  (click)="dateToEndOfNextMonth()"></button>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Quelle</label>
        <div class="col-10 align-self-center">
          <select required="required" [(ngModel)]="currentTransaction.source" name="source">
            <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Ziel</label>
        <div class="col-10 align-self-center">
          <select required="required" [(ngModel)]="currentTransaction.target" name="target">
            <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Kategorie</label>
        <div class="col-10 align-self-center">
          <select required="required" [(ngModel)]="currentTransaction.category" name="category">
            <optgroup *ngFor="let group of groups" label="{{group.name}}">
              <option *ngFor="let category of dataService.getCategorysForGroup(group)" [value]="category.id">
                {{category.name}}</option>
            </optgroup>
            <option></option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Betrag</label>
        <div class="col-10">
          <input class="form-control" id="name" type="number" step="0.01" min="0" required="required"
            [(ngModel)]="currentTransaction.value" name="value" (ngModelChange)="sliderToNumber(shareValuePercent)" />
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Fremdanteil</label>
        <div class="col-10 container align-self-center">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="share" name="share" />
          </div>
          <div class="row justify-content-end" *ngIf="share">
            <div class="col-4">
              <select required="required" [(ngModel)]="sharePerson" name="share.person">
                <option *ngFor="let person of persons" [value]="person.id">{{person.name}}</option>
              </select>
            </div>
          </div>
          <div class="row justify-content-center" *ngIf="share">
            <div class="col-4">
              <input class="form-control" type="number" disabled [(ngModel)]="ownValueAbsolute"
                name="ownValueAbsolute" />
              <input class="form-control" type="number" disabled [(ngModel)]="ownValuePercent" name="ownValuePercent" />
            </div>
            <div class="col-4">
              <input type="range" min="0" max="100" (ngModelChange)="sliderToNumber($event)"
                [(ngModel)]="shareValuePercent" name="shareValuePercent" />
            </div>
            <div class="col-4">
              <input class="form-control" type="number" step="0.01" min="0" max="currentTransaction.value"
                (ngModelChange)="absoluteNumberToSlider($event)" required [(ngModel)]="shareValueAbsolute"
                name="shareValueAbsolute" />
              <input class="form-control" type="number" min="0" max="100" required
                (ngModelChange)="sliderToNumber($event)" [(ngModel)]="shareValuePercent" name="shareValuePercent" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Tags</label>
        <div class="col-10">
          <tag-input [(ngModel)]="temptags" name="tags">
            <tag-input-dropdown [autocompleteItems]="tags" [identifyBy]="'id'" [displayBy]="'name'"
              [appendToBody]="false">
            </tag-input-dropdown>
          </tag-input>
        </div>
      </div>
      <div class="row mb-3">
        <label class="col-2 col-form-label">Beschreibung</label>
        <div class="col-10">
          <input class="form-control" type="text" [(ngModel)]="currentTransaction.description" name="description" />
        </div>
      </div>
    </form>
    <div *ngIf="error" class="alert alert-danger"><span>{{error.message}}</span></div>
  </div>
  <div class="modal-footer">
    <button (click)="editTransaction()" class="btn btn-secondary" [disabled]="requestIsRunning">Speichern</button>
    <button (click)="closeDialog()" class="btn btn-secondary">Abbrechen</button>
  </div>
</ng-template>
