import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    constructor() { }

    getFormattedDate(date: Date): string {
        var formattedDate = new Date(date);
        formattedDate.setTime(formattedDate.getTime() - formattedDate.getTimezoneOffset() * 60000);
        return (formattedDate).toISOString().slice(0, 7);
    }

    getFirstDateInMonth(date: Date): Date {
        if (date == null) {
            date = new Date();
        }
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = 1;
        return new Date(year, month, day);
    }

    getLastDateInMonth(date: Date): Date{
        if (date == null) {
            date = new Date();
        }
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = 0;
        return new Date(year, month, day);
    }

    getNextMonth(date: Date): Date {
        var newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
    }

    getPreviousMonth(date: Date): Date {
        var newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() - 1);
        return newDate;
    }

    getSecondsOffRoundedDate(date: Date): Date {
        date.setMilliseconds(0);
        date.setSeconds(0);
        return date;
    }
}