import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
    selector: 'navbar-component',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent {

    constructor(private authenticationService: AuthenticationService) { }

    ngOnInit() {
        console.info('NavbarComponent ngOnInit()');
    }

    ngOnDestroy() {
        console.info('NavbarComponent ngOnDestroy()');
    }

    getUsernameFromToken() {
        return this.authenticationService.getUsernameFromToken();
    }
}