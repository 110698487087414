import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from '../../_services/alert.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css'],
    host:{
        '[class]' : 'class'
    }
})

export class LoginComponent {
    model: any = {};
    loading: boolean = false;
    class: string = "vh-100";

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService) {
        console.info('LoginComponent constructor()');
        this.authenticationService.logout();
    }

    login() {
        this.alertService.clearAlert();
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(
                success => {
                    this.class = "";
                    this.router.navigate(['/']);
                },
                error => {
                    this.loading = false;
                    this.alertService.error(error.error.message);
                });
    }

    isLoggedIn() {
        return this.authenticationService.isLoggedIn();
    }

}