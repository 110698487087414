<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"
    aria-expanded="false">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbar">
    <div class="col">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" [routerLink]="['/'] ">Übersicht</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" [routerLink]="['/transactions']">Buchungen</a>
        </li>
        <li ngbDropdown class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" id="dropdownData" ngbDropdownToggle>Stammdaten</a>
          <div ngbDropdownMenu aria-labelledby="dropdownData">
            <button class="dropdown-item" [routerLink]="['/groups']">Gruppen</button>
            <button class="dropdown-item" [routerLink]="['/categorys']">Kategorien</button>
            <button class="dropdown-item" [routerLink]="['/accounts']">Konten</button>
            <button class="dropdown-item" [routerLink]="['/persons']">Personen</button>
            <button class="dropdown-item" [routerLink]="['/tags']">Tags</button>
          </div>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" [routerLink]="['/notes']">Notizen</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" [routerLink]="['/tools']">Tools</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
          <a class="nav-link" [routerLink]="['/info']">Info</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/login']">Logout</a>
        </li>
      </ul>
    </div>
    <div class="col">
      <div class="float-end userInfo">
        <strong>{{getUsernameFromToken()}}</strong>
      </div>
    </div>
  </div>
</nav>