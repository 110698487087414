export class Amount {
    id: number;
    display: string;
    current: number;
    future: number;
    showFutureTransactions: boolean;

    constructor(id: number, display: string, showFutureTransactions: boolean) {
        this.id = id;
        this.display = display;
        this.current = 0;
        this.future = 0;
        this.showFutureTransactions = showFutureTransactions;
    }

    increaseCurrent(value: number) {
        this.current = this.current + value;
    }

    decreaseCurrent(value: number) {
        this.current = this.current - value;
    }

    increaseFuture(value: number) {
        this.future = this.future + value;
    }

    decreaseFuture(value: number) {
        this.future = this.future - value;
    }
}