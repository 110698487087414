<div class="d-flex flex-column h-100">
    <div class="row h-100 flex-grow align-items-center justify-content-center">
        <div class="form justify-content-center">
            <div class="input-group justify-content-center">
                <button (click)="import()" class="btn btn-secondary"><a>Import</a></button>
            </div>
            <div class="input-group justify-content-center">
                <button (click)="export()" class="btn btn-secondary"><a>Export</a></button>
            </div>
        </div>
    </div>
</div>