import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NetworkService } from '../../_services/network.service';
import { DataService } from '../../_services/data.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css'],
  host: {
    '[class]': 'class'
  }
})
export class ToolsComponent implements OnInit, OnDestroy {

  class: string = "vh-100";
  constructor(private networkService: NetworkService, private datePipe: DatePipe, private dataService: DataService) { }

  ngOnInit() {
    console.info('ToolsComponent ngOnInit()');
  }

  ngOnDestroy() {
    console.info('ToolsComponent ngOnDestroy()');
  }

  import() {
    let input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.click();
    input.onchange = (event) => this.uploadFileToActivity(input.files);
  }

  export() {
    this.networkService.export().subscribe(response => {
      const blob = new Blob([JSON.stringify(response, null, 2)], { type: 'application/json' });
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = this.datePipe.transform(new Date(), "yyyyMMdd_HHmmss") + "_haushaltsbuch.json";
      a.click();
    });
  }

  uploadFileToActivity(files: FileList) {
    if (files.length > 0) {
      this.dataService.clear();
      this.class = "d-none";
      this.networkService.import(files.item(0)).subscribe(
        () => { },
        () => { },
        () => this.dataService.resolve().then(() => this.class = "vh-100")
      );
    }
  }

}
