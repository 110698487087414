import { Component, OnInit } from '@angular/core';
import packageJson from '../../../../package.json';

@Component({
  templateUrl: './info.component.html',
  styleUrl: './info.component.css'
})
export class InfoComponent implements OnInit {

  // dependencies: Map<string, string> = packageJson.dependencies;
  dependencies: { name: string, version: string }[] = []

  ngOnInit() {
    console.info('InfoComponent ngOnInit()');

    new Map(Object.entries(packageJson.dependencies)).forEach((value: string, key: string) => {
      this.dependencies.push({ name: key, version: value });
    });
  }
}
