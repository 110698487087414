import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { DataService } from '../../_services/data.service';

@Component({
    selector: 'data-component',
    templateUrl: 'data.component.html',
    styleUrls: ['data.component.css']
})

export class DataComponent {
    constructor(private authenticationService: AuthenticationService, private dataService: DataService) { }

    ngOnInit() {
        console.info('DataComponent ngOnInit()');
    }

    ngOnDestroy() {
        console.info('DataComponent ngOnDestroy()');
    }

}