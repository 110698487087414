import { Component, OnInit, Input } from '@angular/core';

import { StyleService } from '../../_services/style.service';

import { Amount } from '../../_models/ui/amount';

@Component({
    selector: '[app-amount]',
    templateUrl: './amount.component.html',
    styles: []
})
export class AmountComponent implements OnInit {

    @Input()
    amount: Amount;

    constructor(public styleService: StyleService) {

    }

    ngOnInit() {
    }

}
