import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Group } from '../../_models/group';
import { DataService } from '../../_services/data.service';
import { EditableComponent } from './../editable/editable.component';

@Component({
    templateUrl: 'groups.component.html',
    host: { 'collision-id': 'GroupsComponent' }
})

export class GroupsComponent extends EditableComponent implements OnInit {

    datatypeName = "Gruppe";
    groups: any[];

    constructor(protected dataService: DataService, protected modalService: NgbModal) {
        super(modalService);
    }

    ngOnInit() {
        console.info('GroupsComponent ngOnInit()');
        this.groups = this.updateAllData();
    }

    openNewDialog() {
        super.openCreateDialog(new Group);
    }

    updateAllData() {
        // load all groups
        let groups = Array.from(this.dataService.groups.values());
        // remove deleted
        groups = groups.filter(group => !group.deleted);
        // sort by date
        groups.sort((a, b) => (a.name > b.name ? 1 : -1));
        // set locally groups
        return groups;
    }

    editData() {
        let tempGroup = JSON.parse(JSON.stringify(this.currentData));
        if (tempGroup.id != null) {
            this.dataService.editGroup(tempGroup).then(result => {
                this.groups = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        } else {
            this.dataService.addGroup(tempGroup).then(result => {
                this.groups = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        }
    }

    deleteData() {
        this.dataService.deleteGroup(this.currentData).then((result) => {
            this.groups = this.updateAllData();
            this.closeDialog();
        }, error => {
            this.error = error;
        });
    }
}