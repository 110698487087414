<div class="row">
  <div class="col text-center">
    <button type="button" class="btn btn-secondary btn-sm fa fa-cog" (click)="toggleSettings()"></button>
  </div>
</div>
<div class="col-4 offset-4" *ngIf="showSettings">
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th class="text-center">Einstellung</th>
        <th class="text-center">Wert</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let setting of settings">
        <tr>
          <td class="text-center">{{setting.description}}</td>
          <td class="text-center">
            <app-flexible-input [model]="setting"></app-flexible-input>
          </td>
          <td class="text-center"><button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-up"
              (click)="resetToDefault(setting)"></button></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="row" *ngIf="settingsLoaded | async">
  <div class="Widget col-4">
    <h3>Kontostände</h3>
    <div class="card card-default">
      <div class="card-body">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th class="text-center">Bezeichnung</th>
              <th class="text-end">Guthaben</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let amount of accountBalances" app-amount [amount]="amount"></tr>
          </tbody>
          <thead class="topBorder">
            <tr>
              <th class="text-center"></th>
              <th class="text-end">Schulden</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let amount of debts" app-amount [amount]="amount"></tr>
          </tbody>
          <thead class="topBorder">
            <tr>
              <th class="text-center"></th>
              <th class="text-end">Vermögen</th>
            </tr>
          </thead>
          <tbody>
            <tr app-amount [amount]="accountBalanceSum"></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Widget col-8">
    <h3>Monatsübersicht</h3>
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col-4 align-self-center">
            <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-left float-end"
              (click)="goToPreviousMonth()"></button>
          </div>
          <div class="col-4">
            <span class="form-control text-center">{{ firstDateInMonth | date : 'MMMM yyyy'}}</span>
          </div>
          <div class="col-4 align-self-center">
            <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-up float-left"
              (click)="goToCurrentMonth()"></button>
            <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-right float-left"
              (click)="goToNextMonth()"></button>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th class="text-center">Geldfluß</th>
                  <th class="text-end">Betrag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">Einnahmen</td>
                  <td [ngClass]="styleService.getStyleForAmount(allGroups.incomeThisMonth)" class="text-end">{{
                    allGroups.incomeThisMonth/100 | currency:'EUR':'symbol'}}</td>
                </tr>
                <tr>
                  <td class="text-center">Ausgaben</td>
                  <td [ngClass]="styleService.getStyleForAmount(allGroups.outcomeThisMonth)" class="text-end">{{
                    allGroups.outcomeThisMonth/100 | currency:'EUR':'symbol'}}</td>
                </tr>
                <tr class="topBorder">
                  <td class="text-center">Summe</td>
                  <td [ngClass]="styleService.getStyleForAmount(allGroups.thisMonth)" class="text-end">{{
                    allGroups.thisMonth/100 | currency:'EUR':'symbol'}}</td>
                </tr>
                <tr>
                  <td class="text-center">Sparquote</td>
                  <td [ngClass]="styleService.getStyleForAmount(allGroups.savingsRateThisMonth)" class="text-end">{{
                    allGroups.savingsRateThisMonth}} %</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th class="text-center">Kategorie</th>
                  <th class="text-end">Betrag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <tr *ngFor="let monthCategory of monthCategorys">
                  <td class="text-center">{{ dataService.getCategoryForId(monthCategory.id).name }}</td>
                  <td [ngClass]="styleService.getStyleForAmount(monthCategory.thisMonth)" class="text-end">{{
                    monthCategory.thisMonth/100 | currency:'EUR':'symbol'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Widget col-md-12">
    <h3>Monatsbilanzen</h3>
    <div class="card card-default">
      <div class="card-body">
        <app-bilance id="bilance" [data]="displayableData" field="bilanceValue"></app-bilance>
      </div>
    </div>
  </div>
  <div class="Widget col-md-12">
    <h3>Vermögen</h3>
    <div class="card card-default">
      <div class="card-body">
        <app-bilance id="wealth" [data]="displayableData" field="wealthValue"></app-bilance>
      </div>
    </div>
  </div>
  <div class="Widget col-md-12">
    <h3>Kategorieanalyse</h3>
    <div class="card card-default">
      <div *ngFor="let group of groups" class="card-body">
        <h4>{{dataService.getGroupForId(group.id).name}}</h4>
        <div class="card card-default">
          <table class="table table-sm table-striped justify-content-md-center">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center w-25">Kategorie</th>
                <th class="text-center">{{firstDateInMonth | date : 'MMMM yyyy'}}</th>
                <th class="text-center">Letzter Monat</th>
                <th></th>
                <th class="text-center">Ø 3 Monate</th>
                <th></th>
                <th class="text-center">Ø 6 Monate</th>
                <th></th>
                <th class="text-center">Ø 12 Monate</th>
                <th></th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let category of getCategorysForGroup(group.id)">
                <td></td>
                <td class="text-center vcenter">{{ dataService.getCategoryForId(category.id).name }}</td>
                <td [ngClass]="styleService.getStyleForAmount(category.thisMonth)" class="text-center vcenter">{{
                  category.thisMonth/100 | currency:'EUR':'symbol'}}</td>
                <td [ngClass]="styleService.getStyleForAmount(category.lastMonth)" class="text-center vcenter">{{
                  category.lastMonth/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="category.thisMonth-category.lastMonth>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="category.thisMonth-category.lastMonth<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(category.avg3)" class="text-center vcenter">{{
                  category.avg3/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="category.thisMonth-category.avg3>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="category.thisMonth-category.avg3<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(category.avg6)" class="text-center vcenter">{{
                  category.avg6/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="category.thisMonth-category.avg6>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="category.thisMonth-category.avg6<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(category.avg12)" class="text-center vcenter">{{
                  category.avg12/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="category.thisMonth-category.avg12>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="category.thisMonth-category.avg12<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td></td>
              </tr>
              <tr class="topBorder">
                <td></td>
                <td class="text-center vcenter">Summe</td>
                <td [ngClass]="styleService.getStyleForAmount(group.thisMonth)" class="text-center vcenter">{{
                  group.thisMonth/100 | currency:'EUR':'symbol'}}</td>
                <td [ngClass]="styleService.getStyleForAmount(group.lastMonth)" class="text-center vcenter">{{
                  group.lastMonth/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="group.thisMonth-group.lastMonth>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="group.thisMonth-group.lastMonth<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(group.avg3)" class="text-center vcenter">{{ group.avg3/100
                  | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="group.thisMonth-group.avg3>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="group.thisMonth-group.avg3<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(group.avg6)" class="text-center vcenter">{{ group.avg6/100
                  | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="group.thisMonth-group.avg6>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="group.thisMonth-group.avg6<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(group.avg12)" class="text-center vcenter">{{
                  group.avg12/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="group.thisMonth-group.avg12>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="group.thisMonth-group.avg12<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-body">
        <h4>Gesamt</h4>
        <div class="card card-default">
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center w-25">Kategorie</th>
                <th class="text-center">{{firstDateInMonth | date : 'MMMM yyyy'}}</th>
                <th class="text-center">Letzter Monat</th>
                <th></th>
                <th class="text-center">Ø 3 Monate</th>
                <th></th>
                <th class="text-center">Ø 6 Monate</th>
                <th></th>
                <th class="text-center">Ø 12 Monate</th>
                <th></th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td class="text-center vcenter">Einnahmen:</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.incomeThisMonth)" class="text-center vcenter">{{
                  allGroups.incomeThisMonth/100 | currency:'EUR':'symbol'}}</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.incomeLastMonth)" class="text-center vcenter">{{
                  allGroups.incomeLastMonth/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.incomeThisMonth-allGroups.incomeLastMonth>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.incomeThisMonth-allGroups.incomeLastMonth<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.incomeAvg3)" class="text-center vcenter">{{
                  allGroups.incomeAvg3/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.incomeThisMonth-allGroups.incomeAvg3>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.incomeThisMonth-allGroups.incomeAvg3<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.incomeAvg6)" class="text-center vcenter">{{
                  allGroups.incomeAvg6/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.incomeThisMonth-allGroups.incomeAvg6>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.incomeThisMonth-allGroups.incomeAvg6<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.incomeAvg12)" class="text-center vcenter">{{
                  allGroups.incomeAvg12/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.incomeThisMonth-allGroups.incomeAvg12>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.incomeThisMonth-allGroups.incomeAvg12<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td class="text-center vcenter">Ausgaben:</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.outcomeThisMonth)" class="text-center vcenter">
                  {{ allGroups.outcomeThisMonth/100 | currency:'EUR':'symbol'}}</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.outcomeLastMonth)" class="text-center vcenter">
                  {{ allGroups.outcomeLastMonth/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeLastMonth>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeLastMonth<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.outcomeAvg3)" class="text-center vcenter">{{
                  allGroups.outcomeAvg3/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeAvg3>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeAvg3<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.outcomeAvg6)" class="text-center vcenter">{{
                  allGroups.outcomeAvg6/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeAvg6>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeAvg6<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.outcomeAvg12)" class="text-center vcenter">{{
                  allGroups.outcomeAvg12/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeAvg12>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.outcomeThisMonth-allGroups.outcomeAvg12<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td></td>
              </tr>
              <tr class="topBorder">
                <td></td>
                <td class="text-center vcenter">Summe</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.thisMonth)" class="text-center vcenter">{{
                  allGroups.thisMonth/100 | currency:'EUR':'symbol'}}</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.lastMonth)" class="text-center vcenter">{{
                  allGroups.lastMonth/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.thisMonth-allGroups.lastMonth>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="allGroups.thisMonth-allGroups.lastMonth<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.avg3)" class="text-center vcenter">{{
                  allGroups.avg3/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.thisMonth-allGroups.avg3>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="allGroups.thisMonth-allGroups.avg3<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.avg6)" class="text-center vcenter">{{
                  allGroups.avg6/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.thisMonth-allGroups.avg6>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="allGroups.thisMonth-allGroups.avg6<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.avg12)" class="text-center vcenter">{{
                  allGroups.avg12/100 | currency:'EUR':'symbol'}}</td>
                <td class="text-center vcenter"><a><i *ngIf="allGroups.thisMonth-allGroups.avg12>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i *ngIf="allGroups.thisMonth-allGroups.avg12<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td class="text-center vcenter">Sparquote</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.savingsRateThisMonth)"
                  class="text-center vcenter">{{ allGroups.savingsRateThisMonth}} %</td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.savingsRateLastMonth)"
                  class="text-center vcenter">{{ allGroups.savingsRateLastMonth}} %</td>
                <td class="text-center vcenter"><a><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateLastMonth>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateLastMonth<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.savingsRateAvg3)" class="text-center vcenter">{{
                  allGroups.savingsRateAvg3}} %</td>
                <td class="text-center vcenter"><a><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateAvg3>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateAvg3<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.savingsRateAvg6)" class="text-center vcenter">{{
                  allGroups.savingsRateAvg6}} %</td>
                <td class="text-center vcenter"><a><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateAvg6>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateAvg6<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td [ngClass]="styleService.getStyleForAmount(allGroups.savingsRateAvg12)" class="text-center vcenter">
                  {{ allGroups.savingsRateAvg12}} %</td>
                <td class="text-center vcenter"><a><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateAvg12>=0"
                      class="fa fa-thumbs-o-up positiveAmount"></i><i
                      *ngIf="allGroups.savingsRateThisMonth-allGroups.savingsRateAvg12<0"
                      class="fa fa-thumbs-o-down negativeAmount"></i></a></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="Widget col-md-12">
    <h3>Kategoriehistorie</h3>
    <div class="card card-default">
      <div class="card-body">
        <div class="row">
          <div class="col align-self-center">
            <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-left float-end"
              (click)="goToPreviousMonth()"></button>
          </div>
          <div class="col">
            <span class="form-control text-center">{{ firstDateInMonth | date : 'MMMM yyyy'}}</span>
          </div>
          <div class="col align-self-center">
            <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-up float-left"
              (click)="goToCurrentMonth()"></button>
            <button type="button" class="btn btn-secondary btn-sm fa fa-angle-double-right float-left"
              (click)="goToNextMonth()"></button>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <select [(ngModel)]=categoryHistoryCategory (ngModelChange)="categoryHistoryCategoryChanged($event)">
              <optgroup *ngFor=" let group of groups" label="{{group.name}} ">
                <option *ngFor="let category of dataService.getCategorysForGroup(group) " [ngValue]="category">
                  {{category.name}}</option>
              </optgroup>
            </select>
          </div>
        </div>
        <app-bilance id="categoryHistory" [data]="displayableData" field="categoryHistoryValue"></app-bilance>
      </div>
    </div>
  </div>
</div>