import { Directive, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Directive()
export abstract class EditableComponent {
    @ViewChild("deleteDialog") protected deleteDialog: TemplateRef<any>;
    @ViewChild("handleDialog") protected handleDialog: TemplateRef<any>;

    protected showActionButtons: boolean[];
    protected abstract datatypeName: String;
    protected dialog: NgbModalRef;
    protected title: String;
    protected currentData: any;
    protected error: String;

    constructor(protected modalService: NgbModal) {
        this.showActionButtons = new Array<boolean>();
    }

    openDeleteDialog(data) {
        this.title = "Lösche " + this.datatypeName;
        this.currentData = data;
        this.dialog = this.modalService.open(this.deleteDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    openEditDialog(data) {
        this.title = "Bearbeite " + this.datatypeName;
        // deep copy transaction and modify values
        this.currentData = JSON.parse(JSON.stringify(data));
        this.dialog = this.modalService.open(this.handleDialog, { centered: true, keyboard: false, size: 'lg' });
    }

    openCreateDialog(data) {
        this.title = "Erstelle " + this.datatypeName;
        this.currentData = data;
        this.dialog = this.modalService.open(this.handleDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    abstract openNewDialog();
    abstract editData();
    abstract deleteData();
    abstract updateAllData();

    closeDialog() {
        if (this.dialog) {
            this.dialog.dismiss();

        }
        //cleanup
        this.dialog = null;
        this.currentData = null;
        this.error = null;
    }
}