<div class="row">
  <div class="col align-self-center">
    <button type="button" class="btn btn-secondary btn-sm fa fa-plus float-left" (click)="openNewDialog()"></button>
  </div>
</div>
<div class="row">
  <table class="table-responsive">
    <table class="table table-sm table-striped table-condensed">
      <thead>
        <tr>
          <th class="text-center">Name</th>
          <th width="80"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let person of persons">
          <tr (mouseover)="showActionButtons[person.id] = true" (mouseleave)="showActionButtons[person.id] = false">
            <td class="text-center text-nowrap">{{ person.name }}</td>
            <td class="vcenter">
              <button type="button" [ngClass]="{invisible: !showActionButtons[person.id]}"
                class="btn btn-secondary btn-sm fa fa-times" (click)="openDeleteDialog(person)">
              </button>
              <button type="button" [ngClass]="{invisible: !showActionButtons[person.id]}"
                class="btn btn-secondary btn-sm fa fa-pencil-square-o" (click)="openEditDialog(person)">
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </table>
</div>
<ng-template #deleteDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <h5 class="modal-title">Soll die Person "{{currentData.name}}" wirklich gelöscht werden?</h5>
    <div *ngIf="error" class="alert alert-danger"><span>{{error.message}}</span></div>
  </div>
  <div class="modal-footer">
    <button (click)="deleteData()" class="btn btn-secondary">Löschen</button>
    <button (click)="closeDialog()" class="btn btn-secondary">Abbrechen</button>
  </div>
</ng-template>
<ng-template #handleDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3 row">
        <label class="col-sm-2 col-form-label">Name</label>
        <div class="col-sm-10">
          <input class="form-control" type="text" [(ngModel)]="currentData.name" name="name" />
        </div>
      </div>
    </form>
    <div *ngIf="error" class="alert alert-danger"><span>{{error.message}}</span></div>
  </div>
  <div class="modal-footer">
    <button (click)="editData()" class="btn btn-secondary">Speichern</button>
    <button (click)="closeDialog()" class="btn btn-secondary">Abbrechen</button>
  </div>
</ng-template>