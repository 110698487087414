import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    constructor() {
        console.info('StorageService constructor()');
    }

    setToken(token){
        if (token == null) {
            localStorage.removeItem('token');
        } else {
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    getToken(){
        return JSON.parse(localStorage.getItem('token'));
    }
}