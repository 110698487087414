import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkService } from '../_services/network.service';
import { DataService } from '../_services/data.service';
import { StorageService } from '../_services/storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthenticationService {

    private jwtHelper: JwtHelperService;
    private isLoginSubject: BehaviorSubject<boolean>;

    constructor(private networkService: NetworkService, private dataService: DataService, private storageService: StorageService) {
        console.info('AuthenticationService constructor()');

        this.jwtHelper = new JwtHelperService();
        this.isLoginSubject = new BehaviorSubject<boolean>(this.hasToken())

        interval(10 * 60 * 1000)
            .subscribe(() => {
                if (this.hasToken()) {
                    this.networkService.reauthenticate().subscribe(response => {
                        this.parseResponseAndSetToken(response);
                    })
                }
            });
    }

    getCurrentToken() {
        return this.storageService.getToken();
    }

    private setCurrentToken(token) {
        this.storageService.setToken(token);
        this.isLoginSubject.next(this.hasToken());
    }

    login(username: string, password: string) {
        return this.networkService.authenticate(username, password)
            .pipe(map(response => {
                this.parseResponseAndSetToken(response);
                return;
            }))
    }

    parseResponseAndSetToken(response) {
        let token = response.token;
        if (token) {
            this.setCurrentToken(token);
        }
    }

    logout() {
        this.setCurrentToken(null);
        this.dataService.clear();
    }

    getUsernameFromToken() {
        let token = this.getCurrentToken();
        let decodedToken = this.jwtHelper.decodeToken(token);
        return decodedToken.sub;
    }

    hasToken(): boolean {
        let token = this.getCurrentToken();
        try {
            if (token != null && !this.jwtHelper.isTokenExpired(token)) {
                return true;
            }
        } catch (e) {
            console.error('malformed token' + e);
        }
        return false;
    }

    isLoggedIn(): Observable<boolean> {
        return this.isLoginSubject.asObservable();
    }
}