<div class="row">
    <table class="table-responsive">
        <table class="table table-sm table-striped table-condensed">
            <thead>
                <tr>
                    <th class="text-center">Name</th>
                    <th class="text-center">Version</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let dependency of dependencies">
                    <tr>
                        <td class="text-center text-nowrap">{{ dependency.name }}</td>
                        <td class="text-center text-nowrap">{{ dependency.version }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </table>
</div>