import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

import { DataService } from '../../_services/data.service';
import { DateService } from '../../_services/date.service';
import { StyleService } from '../../_services/style.service';

import { Amount } from '../../_models/ui/amount';

import { Category } from '../../_models/category';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
    private bilanceDataMap: Map<String, any>;

    accountBalances: Amount[];
    debts: Amount[];
    accountBalanceSum: Amount;

    firstDateInMonth: Date;

    displayableData: any[];

    categorys: any[];
    groups: any[];
    allGroups: any;

    monthCategorys: any[];

    categoryHistoryCategory: any;

    // settings
    settingsLoaded: Promise<boolean>;
    showSettings: boolean = false;
    settings: any[] = [];

    constructor(private dataService: DataService, private dateService: DateService, private currencyPipe: CurrencyPipe, public styleService: StyleService, private datePipe: DatePipe) {
        console.info('HomeComponent constructor()');
    }

    ngOnInit() {
        console.info("HomeComponent ngOnInit()")
        this.initSetting().then(() => {
            this.firstDateInMonth = this.dateService.getFirstDateInMonth(null);
            this.calculateBilanceData();
            this.updateHomeData();
            this.settingsLoaded = Promise.resolve(true);
        });
    }

    private initSetting(): Promise<void> {
        let setting = this.dataService.getSetting();
        if (setting == null) {
            setting = new Map();
        }

        const settingToTypeMap: Map<string, string> = new Map();
        const settingToDescriptionMap: Map<string, string> = new Map();
        const settingToDefaultValueMap: Map<string, string> = new Map();
        const settingToCallbackMap: Map<string, () => void> = new Map();

        let newSettingsCreates = false;
        newSettingsCreates = this.checkAndInitSetting(setting, "numberOfMonths",
            "number", settingToTypeMap,
            "Anzahl der Monate in Graphen", settingToDescriptionMap,
            25, settingToDefaultValueMap,
            () => { }, settingToCallbackMap,
            false) || newSettingsCreates;

        newSettingsCreates = this.checkAndInitSetting(setting, "referenceDate",
            "datetime-local", settingToTypeMap,
            "Referenzdatum", settingToDescriptionMap,
            this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm'), settingToDefaultValueMap,
            () => { this.goToCurrentMonth(new Date(Date.parse(this.dataService.getSetting().get("referenceDate")))) }, settingToCallbackMap,
            true) || newSettingsCreates;

        newSettingsCreates = this.checkAndInitSetting(setting, "showFutureTransactions",
            "checkbox", settingToTypeMap,
            "Beachte zukünftige Transaktionen", settingToDescriptionMap,
            true, settingToDefaultValueMap,
            () => { }, settingToCallbackMap,
            false) || newSettingsCreates;

        newSettingsCreates = this.checkAndInitSetting(setting, "showEmptyAccounts",
            "checkbox", settingToTypeMap,
            "Zeige leere Konten an", settingToDescriptionMap,
            false, settingToDefaultValueMap,
            () => { }, settingToCallbackMap,
            false) || newSettingsCreates;

        return new Promise<void>((resolve, reject) => {
            if (newSettingsCreates) {
                this.dataService.editSetting(setting).then(() => {
                    this.settings = Array.from(this.dataService.getSetting(), (key) => ({
                        "key": key[0],
                        "value": key[1],
                        "type": settingToTypeMap.get(key[0]),
                        "description": settingToDescriptionMap.get(key[0]),
                        "defaultValue": settingToDefaultValueMap.get(key[0]),
                        "callback": settingToCallbackMap.get(key[0])
                    }))
                        .filter((setting) => settingToDescriptionMap.has(setting.key));
                    resolve();
                })
            } else {
                this.settings = Array.from(this.dataService.getSetting(), (key) => ({
                    "key": key[0],
                    "value": key[1],
                    "type": settingToTypeMap.get(key[0]),
                    "description": settingToDescriptionMap.get(key[0]),
                    "defaultValue": settingToDefaultValueMap.get(key[0]),
                    "callback": settingToCallbackMap.get(key[0])
                }))
                    .filter((setting) => settingToDescriptionMap.has(setting.key));
                resolve();
            }

        });
    }

    private checkAndInitSetting(
        setting: Map<string, any>,
        key: string,
        type: string,
        settingToTypeMap: Map<string, string>,
        description: string,
        settingToDescriptionMap: Map<string, string>,
        defaultValue: any,
        settingToDefaultValueMap: Map<string, string>,
        callback: () => void,
        settingToCallbackMap: Map<string, () => void>,
        overwrite: boolean): boolean {
            
        settingToTypeMap.set(key, type);
        settingToDescriptionMap.set(key, description);
        settingToDefaultValueMap.set(key, defaultValue);
        settingToCallbackMap.set(key, callback);
        if (!setting.has(key) || overwrite) {
            setting.set(key, defaultValue);
            return true;
        }
        return false;
    }

    resetToDefault(setting: any) {
        this.updateSetting(setting.key, setting.defaultValue);
    }

    runCallback(setting: any) {
        if(setting != null){
            setting.callback.apply(this);
        }
    }

    updateSetting(key: string, value: any) {
        let matchingSetting;

        this.settings.forEach(setting => {
            if (setting.key == key) {
                setting.value = value;
                
                matchingSetting = setting;
            }
        });
        let setting = this.dataService.getSetting();
        setting.set(key, value);
        this.dataService.editSetting(setting);

        this.calculateBilanceData();
        this.updateHomeData();
        this.runCallback(matchingSetting);
    }

    private calculateBilanceData() {
        var bilanceDataMap = new Map<String, any>();
        const referenceDate = new Date(Date.parse(this.dataService.getSetting().get("referenceDate")));
        const showFutureTransactions: boolean = this.dataService.getSetting().get("showFutureTransactions");
        const showEmptyAccounts: boolean = this.dataService.getSetting().get("showEmptyAccounts");

        var firstDate = new Date('2000-01-01');
        var lastDate = new Date();
        lastDate.setFullYear(lastDate.getFullYear() + 2);

        var currentDate = new Date(firstDate);
        while (currentDate < lastDate) {
            var y = currentDate.getFullYear();
            var m = currentDate.getMonth();
            var tempFirstDateInMonth = this.dateService.getFirstDateInMonth(currentDate);
            var tempLastDateInMonth = this.dateService.getLastDateInMonth(currentDate);
            var label = this.dateService.getFormattedDate(tempFirstDateInMonth);
            bilanceDataMap.set(label, {
                "firstDateInMonth": tempFirstDateInMonth,
                "lastDateInMonth": tempLastDateInMonth,
                "label": label,
                "bilanceValue": 0.0,
                "wealthValue": 0.0,
                "categoryHistoryValue": 0.0,
                "categorys": [],
                "transactions": []
            })

            currentDate = this.dateService.getNextMonth(currentDate);
        }

        // prepare balances
        let accountBalances: Amount[] = [];
        for (let account of Array.from(this.dataService.accounts.values())) {
            var id = account.id;
            accountBalances[id] = new Amount(id, this.dataService.getAccountForId(id).name, showFutureTransactions);
        }

        // prepare debts
        let debts: Amount[] = [];
        for (let person of Array.from(this.dataService.persons.values())) {
            var id = person.id;
            debts[person.id] = new Amount(id, this.dataService.getPersonForId(id).name, showFutureTransactions);

        }

        for (let transaction of Array.from(this.dataService.transactions.values())) {
            if (transaction.active && !transaction.deleted) {
                var sourceAccount = this.dataService.getAccountForId(transaction.source);
                var targetAccount = this.dataService.getAccountForId(transaction.target);

                let transactionDate = new Date(transaction.date);

                if (transactionDate <= referenceDate) {
                    //calculate current balances
                    accountBalances[sourceAccount.id].decreaseCurrent(transaction.value);
                    accountBalances[targetAccount.id].increaseCurrent(transaction.value);
                    //calculate current debts
                    if (transaction.share) {
                        let person = this.dataService.getPersonForId(transaction.share.person);
                        if (sourceAccount.intern && !targetAccount.intern) {
                            debts[person.id].increaseCurrent(transaction.share.value);
                        }
                        if (targetAccount.intern && targetAccount.intern) {
                            debts[person.id].decreaseCurrent(transaction.share.value);
                        }
                    }
                }

                //calculate future balances
                accountBalances[sourceAccount.id].decreaseFuture(transaction.value);
                accountBalances[targetAccount.id].increaseFuture(transaction.value);
                //calculate future debts
                if (transaction.share) {
                    let person = this.dataService.getPersonForId(transaction.share.person);
                    if (sourceAccount.intern && !targetAccount.intern) {
                        debts[person.id].increaseFuture(transaction.share.value);
                    }
                    if (!sourceAccount.intern && targetAccount.intern) {
                        debts[person.id].decreaseFuture(transaction.share.value);
                    }
                }

                var label = this.dateService.getFormattedDate(transactionDate);
                var tempGroupedMonth = bilanceDataMap.get(label);
                if (tempGroupedMonth) {
                    tempGroupedMonth.transactions.push(transaction);

                    let balance = this.dataService.getBalance(transaction);
                    tempGroupedMonth.bilanceValue += balance.income;
                    tempGroupedMonth.bilanceValue -= balance.outcome;

                    bilanceDataMap.set(label, tempGroupedMonth);
                }
            }
        }

        // calculate wealth based on balance
        var wealthMap = new Map<String, Number>();
        for (let tempGroupedMonth of Array.from(bilanceDataMap.values())) {
            wealthMap.set(tempGroupedMonth.label, 0);
        }
        var aLoop = Array.from(bilanceDataMap.values());
        var bLoop = Array.from(bilanceDataMap.values());
        for (let tempGroupedMonth of aLoop) {
            for (let temp2GroupedMonth of bLoop) {
                if (temp2GroupedMonth.lastDateInMonth <= tempGroupedMonth.lastDateInMonth) {
                    let label = tempGroupedMonth.label;
                    let value = wealthMap.get(label) + temp2GroupedMonth.bilanceValue;
                    wealthMap.set(label, value);
                    // return;
                }
            }
        }
        for (let key of Array.from(wealthMap.keys())) {
            var tempGroupedMonth = bilanceDataMap.get(key);
            tempGroupedMonth.wealthValue = wealthMap.get(key);
            bilanceDataMap.set(key, tempGroupedMonth);
        }
        this.bilanceDataMap = bilanceDataMap;

        // remove external balances 
        accountBalances = accountBalances.filter(accountBalance => {
            let account = this.dataService.getAccountForId(accountBalance.id);
            return account.intern && !account.deleted;
        });

        // remove debts depending on settings
        debts = debts.filter(debt => {
            if (showEmptyAccounts) {
                return true;
            } else if (!showEmptyAccounts && !showFutureTransactions) {
                return debt.current != 0;
            } else {
                return debt.current != 0 || debt.future != 0;
            }
        });

        // calculate summary        
        var accountBalanceSum = new Amount(0, "", showFutureTransactions);
        for (let accountBalance of accountBalances) {
            accountBalanceSum.increaseCurrent(accountBalance.current);
            accountBalanceSum.increaseFuture(accountBalance.future);
        }
        for (let debt of debts) {
            accountBalanceSum.increaseCurrent(debt.current);
            accountBalanceSum.increaseFuture(debt.future);
        }

        // remove accounts depending on settings
        accountBalances = accountBalances.filter(accountBalance => {
            if (showEmptyAccounts) {
                return true;
            } else if (!showEmptyAccounts && !showFutureTransactions) {
                return accountBalance.current != 0;
            } else {
                return accountBalance.current != 0 || accountBalance.future != 0;
            }
        });

        // sort by default sort function
        accountBalances.sort((amountA, amountB) => this.dataService.sortAccount(this.dataService.getAccountForId(amountA.id), this.dataService.getAccountForId(amountB.id)));

        // make visible for template
        this.debts = debts;
        this.accountBalances = accountBalances;
        this.accountBalanceSum = accountBalanceSum;
    }

    private updateHomeData() {
        this.updateCategoryHistory();
        this.updateCategoryAnalysis();
    }

    goToPreviousMonth() {
        var newDate = this.dateService.getPreviousMonth(this.firstDateInMonth);
        this.firstDateInMonth = this.dateService.getFirstDateInMonth(newDate);
        this.updateHomeData();
    };

    goToCurrentMonth(date: Date) {
        this.firstDateInMonth = this.dateService.getFirstDateInMonth(date);
        this.updateHomeData();
    };

    goToNextMonth() {
        var newDate = this.dateService.getNextMonth(this.firstDateInMonth);
        this.firstDateInMonth = this.dateService.getFirstDateInMonth(newDate);
        this.updateHomeData();
    };

    categoryHistoryCategoryChanged(event: Category) {
        this.updateCategoryHistory();
    }

    private updateCategoryHistory() {
        var displayableMonths = this.dataService.getSetting().get("numberOfMonths");
        var firstDateInMonth = this.dateService.getFirstDateInMonth(this.firstDateInMonth);
        var lastDateInMonth = this.dateService.getLastDateInMonth(this.firstDateInMonth);
        firstDateInMonth.setMonth(firstDateInMonth.getMonth() - displayableMonths + 1);

        var displayableBilanceData = new Array();
        for (let tempGroupedMonth of Array.from(this.bilanceDataMap.values())) {
            if ((tempGroupedMonth.firstDateInMonth >= firstDateInMonth && tempGroupedMonth.lastDateInMonth <= lastDateInMonth)) {
                displayableBilanceData.push(tempGroupedMonth);
            }
        }

        for (let tempGroupedMonth of displayableBilanceData) {
            let tempCategoryHistory = {
                label: tempGroupedMonth.label,
                categoryHistoryValue: 0.0
            }
            tempGroupedMonth.categorys = [];
            for (let transaction of tempGroupedMonth.transactions) {
                if (transaction.active) {
                    var sourceAccount = this.dataService.getAccountForId(transaction.source);
                    var targetAccount = this.dataService.getAccountForId(transaction.target);
                    var groupedCategory = tempGroupedMonth.categorys.find(function (category) {
                        return category.id == transaction.category;
                    });
                    if (!groupedCategory) {
                        groupedCategory = {
                            id: transaction.category, income: 0, outcome: 0, transactions: []
                        };
                        tempGroupedMonth.categorys.push(groupedCategory);
                    }
                    groupedCategory.transactions.push(transaction);

                    let balance = this.dataService.getBalance(transaction);
                    groupedCategory.income += balance.income;
                    groupedCategory.outcome -= balance.outcome;
                }
            }
            if (this.categoryHistoryCategory == null) {
                var category = this.dataService.categorys.get(0);
                if (category) {
                    this.categoryHistoryCategory = category;
                }
            }
            if (this.categoryHistoryCategory != null) {
                tempGroupedMonth.categoryHistoryValue = 0;
                for (let category of tempGroupedMonth.categorys) {
                    if (category.id == this.categoryHistoryCategory.id) {
                        tempGroupedMonth.categoryHistoryValue = category.income + category.outcome;
                        break;
                    }
                }
            }
        }
        this.displayableData = displayableBilanceData;
    };

    private updateCategoryAnalysis() {
        var categorys = Array.from(this.dataService.categorys.values());

        var categoryBalanceMap = new Map<number, any>();
        for (let tempCategory of categorys) {
            var category = {
                id: tempCategory.id,
                name: tempCategory.name,
                group: tempCategory.group,
                incomeThisMonth: 0,
                outcomeThisMonth: 0,
                thisMonth: 0,
                incomeLastMonth: 0,
                outcomeLastMonth: 0,
                lastMonth: 0,
                incomeAvg3: 0,
                outcomeAvg3: 0,
                avg3: 0,
                incomeAvg6: 0,
                outcomeAvg6: 0,
                avg6: 0,
                incomeAvg12: 0,
                outcomeAvg12: 0,
                avg12: 0
            }

            var values = this.calculateAVGMonth(tempCategory, 0);
            category.incomeThisMonth = values.income;
            category.outcomeThisMonth = values.outcome;
            category.thisMonth = category.incomeThisMonth + category.outcomeThisMonth;
            values = this.calculateAVGMonth(tempCategory, 1);
            category.incomeLastMonth = values.income;
            category.outcomeLastMonth = values.outcome;
            category.lastMonth = category.incomeLastMonth + category.outcomeLastMonth;
            values = this.calculateAVGMonth(tempCategory, 3);
            category.incomeAvg3 = values.income;
            category.outcomeAvg3 = values.outcome;
            category.avg3 = category.incomeAvg3 + category.outcomeAvg3;
            values = this.calculateAVGMonth(tempCategory, 6);
            category.incomeAvg6 = values.income;
            category.outcomeAvg6 = values.outcome;
            category.avg6 = category.incomeAvg6 + category.outcomeAvg6;
            values = this.calculateAVGMonth(tempCategory, 12);
            category.incomeAvg12 = values.income;
            category.outcomeAvg12 = values.outcome;
            category.avg12 = category.incomeAvg12 + category.outcomeAvg12;

            categoryBalanceMap.set(tempCategory.id, category);
        }
        this.categorys = Array.from(categoryBalanceMap.values());

        var groups = Array.from(this.dataService.groups.values());
        var groupBalanceMap = new Map<number, any>();
        for (let tempGroup of groups) {
            let categorys = Array.from(categoryBalanceMap.values()).filter(category => {
                return category.group == tempGroup.id;
            });

            var group = {
                id: tempGroup.id,
                deleted: tempGroup.deleted,
                name: tempGroup.name,
                incomeThisMonth: 0,
                outcomeThisMonth: 0,
                thisMonth: 0,
                incomeLastMonth: 0,
                outcomeLastMonth: 0,
                lastMonth: 0,
                incomeAvg3: 0,
                outcomeAvg3: 0,
                avg3: 0,
                incomeAvg6: 0,
                outcomeAvg6: 0,
                avg6: 0,
                incomeAvg12: 0,
                outcomeAvg12: 0,
                avg12: 0
            }

            group.incomeThisMonth = this.calculateSUM(categorys.map(category => category.incomeThisMonth));
            group.outcomeThisMonth = this.calculateSUM(categorys.map(category => category.outcomeThisMonth));
            group.thisMonth = group.incomeThisMonth + group.outcomeThisMonth;
            group.incomeLastMonth = this.calculateSUM(categorys.map(category => category.incomeLastMonth));
            group.outcomeLastMonth = this.calculateSUM(categorys.map(category => category.outcomeLastMonth));
            group.lastMonth = group.incomeLastMonth + group.outcomeLastMonth;
            group.incomeAvg3 = this.calculateSUM(categorys.map(category => category.incomeAvg3));
            group.outcomeAvg3 = this.calculateSUM(categorys.map(category => category.outcomeAvg3));
            group.avg3 = group.incomeAvg3 + group.outcomeAvg3;
            group.incomeAvg6 = this.calculateSUM(categorys.map(category => category.incomeAvg6));
            group.outcomeAvg6 = this.calculateSUM(categorys.map(category => category.outcomeAvg6));
            group.avg6 = group.incomeAvg6 + group.outcomeAvg6;
            group.incomeAvg12 = this.calculateSUM(categorys.map(category => category.incomeAvg12));
            group.outcomeAvg12 = this.calculateSUM(categorys.map(category => category.outcomeAvg12));
            group.avg12 = group.incomeAvg12 + group.outcomeAvg12;

            groupBalanceMap.set(group.id, group);
        }
        this.groups = Array.from(groupBalanceMap.values()).filter(group => {
            return group.deleted == false;
        }).sort(function (a, b) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });

        var allGroups = {
            incomeThisMonth: 0,
            outcomeThisMonth: 0,
            thisMonth: 0,
            savingsRateThisMonth: 0,
            incomeLastMonth: 0,
            outcomeLastMonth: 0,
            lastMonth: 0,
            savingsRateLastMonth: 0,
            incomeAvg3: 0,
            outcomeAvg3: 0,
            avg3: 0,
            savingsRateAvg3: 0,
            incomeAvg6: 0,
            outcomeAvg6: 0,
            avg6: 0,
            savingsRateAvg6: 0,
            incomeAvg12: 0,
            outcomeAvg12: 0,
            avg12: 0,
            savingsRateAvg12: 0
        }

        allGroups.incomeThisMonth = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.incomeThisMonth));
        allGroups.outcomeThisMonth = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.outcomeThisMonth));
        allGroups.thisMonth = allGroups.incomeThisMonth + allGroups.outcomeThisMonth;
        allGroups.savingsRateThisMonth = (allGroups.incomeThisMonth != 0) ? Math.max(0, Math.floor(allGroups.thisMonth * 100 / allGroups.incomeThisMonth)) : 0;

        allGroups.incomeLastMonth = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.incomeLastMonth));
        allGroups.outcomeLastMonth = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.outcomeLastMonth));
        allGroups.lastMonth = allGroups.incomeLastMonth + allGroups.outcomeLastMonth;
        allGroups.savingsRateLastMonth = (allGroups.incomeLastMonth != 0) ? Math.max(0, Math.floor(allGroups.lastMonth * 100 / allGroups.incomeLastMonth)) : 0;

        allGroups.incomeAvg3 = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.incomeAvg3));
        allGroups.outcomeAvg3 = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.outcomeAvg3));
        allGroups.avg3 = allGroups.incomeAvg3 + allGroups.outcomeAvg3;
        allGroups.savingsRateAvg3 = (allGroups.incomeAvg3 != 0) ? Math.max(0, Math.floor(allGroups.avg3 * 100 / allGroups.incomeAvg3)) : 0;

        allGroups.incomeAvg6 = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.incomeAvg6));
        allGroups.outcomeAvg6 = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.outcomeAvg6));
        allGroups.avg6 = allGroups.incomeAvg6 + allGroups.outcomeAvg6;
        allGroups.savingsRateAvg6 = (allGroups.incomeAvg6 != 0) ? Math.max(0, Math.floor(allGroups.avg6 * 100 / allGroups.incomeAvg6)) : 0;

        allGroups.incomeAvg12 = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.incomeAvg12));
        allGroups.outcomeAvg12 = this.calculateSUM(Array.from(groupBalanceMap.values()).map(group => group.outcomeAvg12));
        allGroups.avg12 = allGroups.incomeAvg12 + allGroups.outcomeAvg12;
        allGroups.savingsRateAvg12 = (allGroups.incomeAvg12 != 0) ? Math.max(0, Math.floor(allGroups.avg12 * 100 / allGroups.incomeAvg12)) : 0;

        this.allGroups = allGroups;

        this.monthCategorys = Array.from(categoryBalanceMap.values()).filter(category => {
            return category.incomeThisMonth != 0 || category.outcomeThisMonth != 0;
        }).sort(function (a, b) { return b.thisMonth - a.thisMonth })
    };

    getCategorysForGroup(id: number) {
        return this.categorys //
            .filter(category => category.group == id) //
            .filter(category => //
                category.thisMonth != 0 || //
                category.lastMonth != 0 || //
                category.avg3 != 0 || //
                category.avg6 != 0 || //
                category.avg12 != 0) //
            .sort(function (a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
    }

    private calculateAVGMonth(category, countOfMonth): any {
        var tempDate = new Date(this.firstDateInMonth);
        var incomeValues = [];
        var outcomeValues = [];

        var tempResult;
        if (countOfMonth > 0) {
            for (var i = 0; i < countOfMonth; i++) {
                tempDate.setMonth(tempDate.getMonth() - 1);
                tempResult = this.calculateValues(category, tempDate, incomeValues, outcomeValues);
            }
        } else {
            tempResult = this.calculateValues(category, tempDate, incomeValues, outcomeValues);
        }
        incomeValues = tempResult.income;
        outcomeValues = tempResult.outcome;
        return { income: this.calculateAVG(incomeValues), outcome: this.calculateAVG(outcomeValues) };
    };

    private calculateValues(category, tempDate, incomeValues, outcomeValues): any {
        var label = this.dateService.getFormattedDate(tempDate);
        var groupedMonth = this.bilanceDataMap.get(label);
        if (groupedMonth) {
            var groupedCategory = groupedMonth.categorys.find(function (tempCategory) {
                return category.id == tempCategory.id;
            });
            if (groupedCategory) {
                incomeValues.push(groupedCategory.income);
                outcomeValues.push(groupedCategory.outcome);
            } else {
                incomeValues.push(0);
                outcomeValues.push(0);
            }
        }
        return { income: incomeValues, outcome: outcomeValues };
    }

    private calculateAVG(values): number {
        return this.calculateSUM(values) / values.length;
    };

    private calculateSUM(values): number {
        var sum = 0;
        for (var i = 0; i < values.length; i++) {
            sum += parseInt(values[i], 10);
        }
        return sum;
    };

    toggleSettings() {
        this.showSettings = !this.showSettings;
    }
}