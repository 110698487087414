import { DatePipe } from '@angular/common';
import { Component, TemplateRef, ViewChild } from '@angular/core';

import { AuthenticationService } from '../../_services/authentication.service';
import { DataService } from '../../_services/data.service';
import { DateService } from '../../_services/date.service';
import { StyleService } from '../../_services/style.service';

import { Account } from '../../_models/account';
import { Category } from '../../_models/category';
import { Group } from '../../_models/group';
import { Person } from '../../_models/person';
import { Tag } from '../../_models/tag';
import { Transaction } from '../../_models/transaction';

import { Amount } from '../../_models/ui/amount';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: 'transactions.component.html'
})

export class TransactionsComponent {
    @ViewChild("deleteDialog") private deleteDialog: TemplateRef<any>;
    @ViewChild("handleDialog") private handleDialog: TemplateRef<any>;

    private accounts: Account[];
    private groups: Group[];
    private persons: Person[];
    private tags: Tag[];
    private categorys: Category[];
    private limit: number;
    transactions: Transaction[];
    transactionsLength: Number;
    transactionsIncome: Amount;
    transactionsOutcome: Amount;
    transactionsSum: Amount;
    private showActionButtons: boolean[];
    filtertext: String;
    showStatistics: boolean;
    firstDateInMonth: Date;
    dateStart: string;
    dateEnd: string;
    title: string;
    requestIsRunning: boolean;

    //dialog handle
    private dialog: NgbModalRef;
    private currentTransaction: Transaction;
    private error: String;

    private shareValueAbsolute: number;
    private shareValuePercent: number;
    private ownValueAbsolute: number;
    private ownValuePercent: number;
    private share: boolean;
    private sharePerson: any;
    private temptags: any[];
    private latestTransactionId: number;

    constructor(private authenticationService: AuthenticationService, private dataService: DataService, private dateService: DateService, private styleService: StyleService, private modalService: NgbModal, private datePipe: DatePipe) {
    }

    ngOnInit() {
        this.showStatistics = false;
        this.requestIsRunning = false;
        this.showActionButtons = new Array<boolean>()
        this.accounts = Array.from(this.dataService.accounts.values()).filter(account => !account.deleted).sort((a, b) => this.dataService.sortAccount(a, b));
        this.groups = Array.from(this.dataService.groups.values()).sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        this.persons = Array.from(this.dataService.persons.values());
        this.tags = Array.from(this.dataService.tags.values()).sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        this.categorys = Array.from(this.dataService.categorys.values()).sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        this.filterChanged(null, null, null, "");
        this.firstDateInMonth = this.dateService.getFirstDateInMonth(null);
    }

    updateTransactions() {
        // load all transactions
        let transactions = Array.from(this.dataService.transactions.values());
        // filter by filtertext
        let dateStart = this.getFormattedDate(this.dateStart);
        let dateEnd = this.getFormattedDate(this.dateEnd);
        if (dateEnd != null) {
            dateEnd.setHours(23);
            dateEnd.setMinutes(59);
            dateEnd.setSeconds(59);
        }
        transactions = transactions.filter((transaction) => this.filterTransaction(transaction, dateStart, dateEnd));
        // calculate values
        let transactionsIncome = new Amount(0, "Einnahmen", true);
        let transactionsOutcome = new Amount(1, "Ausgaben", true);
        let now = new Date();
        for (let transaction of transactions) {
            if (transaction.active) {
                let balance = this.dataService.getBalance(transaction);
                if (new Date(transaction.date) < now) {
                    transactionsIncome.increaseCurrent(balance.income);
                    transactionsOutcome.decreaseCurrent(balance.outcome);
                }
                transactionsIncome.increaseFuture(balance.income);
                transactionsOutcome.decreaseFuture(balance.outcome);
            }
        }
        this.transactionsIncome = transactionsIncome;
        this.transactionsOutcome = transactionsOutcome;

        let transactionsSum = new Amount(2, "Summe", true);
        transactionsSum.increaseCurrent(transactionsIncome.current);
        transactionsSum.increaseCurrent(transactionsOutcome.current);
        transactionsSum.increaseFuture(transactionsIncome.future);
        transactionsSum.increaseFuture(transactionsOutcome.future);
        this.transactionsSum = transactionsSum;
        this.transactionsLength = transactions.length;
        // sort by date
        transactions.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        // limit number of displayed transactions
        transactions = transactions.filter((transaction, index) => index < this.limit);
        // sort tags
        for (let transaction of transactions) {
            transaction.tags.sort((a, b) => {
                if (this.dataService.getTagForId(a).name < this.dataService.getTagForId(b).name) return -1;
                if (this.dataService.getTagForId(a).name > this.dataService.getTagForId(b).name) return 1;
                return 0;
            });
        }
        // set locally transactions
        return transactions;
    }

    filterChanged(dateStart, dateEnd, event, text) {
        this.limit = 50;

        this.dateStart = dateStart;
        this.dateEnd = dateEnd;

        var isAddFilterEvent = event != null && (event.shiftKey == true || event.ctrlKey == true);
        if (isAddFilterEvent == true) {
            this.filtertext += '&&' + text;
        } else {
            this.filtertext = text;
        }

        this.transactions = this.updateTransactions();
        this.highlightLatestElement();
    }

    filterTransaction(transaction: Transaction, dateStart: Date, dateEnd: Date) {
        var returnValue = false;
        var searchText = this.filtertext.toLowerCase();

        var splittedByAnd = searchText.split('&&');
        for (let splittedByAndEntry of splittedByAnd) {
            var negated = splittedByAndEntry.startsWith('!');
            if (negated == true) {
                splittedByAndEntry = splittedByAndEntry.substring(1);
            }

            var result = this.transactionMatchesString(transaction, splittedByAndEntry);
            if (negated == true) {
                result = !result;
            }
            if (result == false) {
                return false;
            }
        }

        let transactionDate = new Date(transaction.date);
        if (dateStart != null && dateStart > transactionDate) {
            return false;
        }
        if (dateEnd != null && dateEnd < transactionDate) {
            return false;
        }

        return true;
    }

    transactionMatchesString(transaction: Transaction, searchText: string) {
        var returnValue = false;

        // check description
        returnValue = (transaction.description.toLowerCase().indexOf(searchText) > -1);

        // check tagMatch
        if (!returnValue) {
            let tags = transaction.tags.map(tagId => {
                return this.dataService.getTagForId(tagId);
            })
            returnValue = tags.some(function (tag) {
                if (tag.name.toLowerCase().indexOf(searchText) > -1) {
                    return true;
                }
            });
        }

        // check category
        if (!returnValue) {
            returnValue = (this.dataService.getCategoryForId(transaction.category).name.toLowerCase().indexOf(searchText) > -1)
        }

        // check source
        if (!returnValue) {
            returnValue = (this.dataService.getAccountForId(transaction.source).name.toLowerCase().indexOf(searchText) > -1)
        }

        // check target
        if (!returnValue) {
            returnValue = (this.dataService.getAccountForId(transaction.target).name.toLowerCase().indexOf(searchText) > -1)
        }

        // check share
        if (!returnValue) {
            if (transaction.share) {
                returnValue = (this.dataService.getPersonForId(transaction.share.person).name.toLowerCase().indexOf(searchText) > -1)
            }
        }

        return returnValue;
    }

    parseDate(event: string) {
        var lastValidDate = new Date(this.currentTransaction.date);
        if (event != '') {
            this.currentTransaction.date = new Date(Date.parse(event));
        } else {
            setTimeout(() => {
                this.currentTransaction.date = new Date(lastValidDate);
            }, 0);
            var tempDate = new Date(lastValidDate);
            tempDate.setMinutes(tempDate.getMinutes() + 1);
            this.currentTransaction.date = tempDate;
        }
    }

    sliderToNumber(newValue: number) {
        this.shareValueAbsolute = Math.round(newValue / 100 * this.currentTransaction.value * 100) / 100;
        this.ownValueAbsolute = Math.round(Math.max(this.currentTransaction.value - this.shareValueAbsolute, 0) * 100) / 100;
        this.ownValuePercent = Math.max(100 - newValue, 0);
    };

    absoluteNumberToSlider(newValue: number) {
        this.shareValuePercent = Math.round(newValue / this.currentTransaction.value * 100 * 1) / 1;
        this.ownValueAbsolute = Math.round(Math.max(this.currentTransaction.value - newValue, 0) * 100) / 100;
        this.ownValuePercent = Math.max(100 - this.shareValuePercent, 0);
    };

    getOwnValueAbsolute(transaction: Transaction) {
        return Math.round(Math.max(transaction.value - transaction.share.value, 0) * 100) / 100
    }

    getOwnValuePercent(transaction: Transaction) {
        var ownValueAbsolute = this.getOwnValueAbsolute(transaction);
        if (transaction.value === 0) {
            return 100;
        }
        var ownValuePercent = Math.round(ownValueAbsolute / transaction.value * 100 * 1) / 1;
        return Math.max(ownValuePercent, 0);
    }

    onScrollDown() {
        this.limit = +this.limit + 20;
        this.transactions = this.updateTransactions();
        this.highlightLatestElement();
    }

    moveTransaction(transaction: Transaction, direction: String) {
        var newDate = null;
        this.transactions.forEach((tempTransaction, index) => {
            if (tempTransaction.id === transaction.id && !newDate) {
                if (direction == 'DOWN' && (index + 1 <= this.transactions.length) && typeof this.transactions[index + 1] != 'undefined') {
                    newDate = new Date(this.transactions[index + 1].date);
                    newDate.setMinutes(newDate.getMinutes() - 1);
                } else if (direction == 'UP' && (index - 1 >= 0) && typeof this.transactions[index - 1] != 'undefined') {
                    newDate = new Date(this.transactions[index - 1].date);
                    newDate.setMinutes(newDate.getMinutes() + 1);
                }
            }
        });

        if (newDate) {
            let tempTransaction = JSON.parse(JSON.stringify(transaction));
            tempTransaction.date = newDate;
            this.dataService.editTransaction(tempTransaction).then((result) => {
                this.transactions = this.updateTransactions();
                this.showActionButtons[transaction.id] = false;
                this.highlightLatestElement();
            }, error => {
                this.error = error;
            });
        }
    }

    deleteTransaction() {
        this.dataService.deleteTransaction(this.currentTransaction).then((result) => {
            this.transactions = this.updateTransactions();
            this.closeDialog();
        }, error => {
            this.error = error;
        });
    }

    toggleActive(transaction: Transaction) {
        let tempTransaction = JSON.parse(JSON.stringify(transaction));
        tempTransaction.active = !tempTransaction.active;
        this.dataService.editTransaction(tempTransaction).then((result) => {
            this.transactions = this.updateTransactions();
        }, error => {
            this.error = error;
        });
    }

    editTransaction() {
        this.requestIsRunning = true;
        let tempTransaction = this.prepareTransactionForModel(this.currentTransaction);

        Promise.all(tempTransaction.promises).then(promise => {
            delete tempTransaction.promises;
            if (tempTransaction.id != null) {
                this.dataService.editTransaction(tempTransaction).then(result => {
                    this.transactions = this.updateTransactions();
                    this.closeDialog();
                    this.highlightLatestElement();
                }, error => {
                    this.error = error;
                })
            } else {
                this.dataService.addTransaction(tempTransaction).then(result => {
                    this.transactions = this.updateTransactions();
                    this.closeDialog();
                    this.highlightLatestElement();
                }, error => {
                    this.error = error;
                })
            }
        }).then(promise => {
            this.requestIsRunning = false;
        })
    }

    //dialog handling

    openDeleteTransactionDialog(transaction) {
        this.title = "Lösche Buchung";
        this.currentTransaction = transaction;
        this.dialog = this.modalService.open(this.deleteDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    openEditTransactionDialog(transaction) {
        this.title = "Bearbeite Buchung";
        this.prepareTransactionForView(transaction);
        this.dialog = this.modalService.open(this.handleDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    openCopyTransactionDialog(transaction) {
        this.title = "Erstelle Buchung";
        this.prepareTransactionForView(transaction);
        // delete id
        delete this.currentTransaction.id;
        // modify date
        this.currentTransaction.date = this.dateService.getSecondsOffRoundedDate(new Date());

        this.dialog = this.modalService.open(this.handleDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    openNewTransactionDialog() {
        this.title = "Erstelle Buchung";
        let transaction = new Transaction();
        // date
        transaction.date = this.dateService.getSecondsOffRoundedDate(new Date());
        // source
        if (this.accounts.length > 0) {
            transaction.source = this.accounts[0].id;
            transaction.target = this.accounts[0].id;
        }
        // target
        if (this.accounts.length > 1) {
            transaction.target = this.accounts[1].id;
        }
        // value
        transaction.value = 1000;
        // description
        transaction.description = "";
        // deleted
        transaction.deleted = false;
        // tags
        transaction.tags = [];
        // category
        if (this.categorys.length > 0) {
            transaction.category = this.categorys[0].id;
        }
        // active
        transaction.active = true;
        // share

        this.prepareTransactionForView(transaction);
        this.dialog = this.modalService.open(this.handleDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    private prepareTransactionForView(transaction) {
        // deep copy transaction and modify values
        this.currentTransaction = JSON.parse(JSON.stringify(transaction))
        // value
        this.currentTransaction.value = this.currentTransaction.value / 100;
        //share
        if (this.currentTransaction.share != null) {
            this.share = true;
            this.shareValueAbsolute = this.currentTransaction.share.value / 100;
            this.sharePerson = this.currentTransaction.share.person;
        } else {
            this.share = false;
            this.shareValueAbsolute = this.currentTransaction.value / 2
            if (this.persons.length > 0) {
                this.sharePerson = this.persons[0].id;
            }
        }
        //tags
        this.temptags = [];
        for (let tag of this.currentTransaction.tags) {
            this.temptags.push({
                value: tag,
                display: this.dataService.getTagForId(tag).name
            })
        }

        this.absoluteNumberToSlider(this.shareValueAbsolute);
    }

    private prepareTransactionForModel(transaction) {
        let tempTransaction = JSON.parse(JSON.stringify(transaction));
        // date
        tempTransaction.date = this.dateService.getSecondsOffRoundedDate(new Date(tempTransaction.date));
        // source
        tempTransaction.source = parseInt(transaction.source);
        // target
        tempTransaction.target = parseInt(transaction.target);
        // category
        tempTransaction.category = parseInt(transaction.category);

        // value
        tempTransaction.value = Math.round(tempTransaction.value * 100);
        // share
        if (this.share) {
            tempTransaction.share = {
                value: Math.round(this.shareValueAbsolute * 100),
                person: parseInt(this.sharePerson)
            }
        } else {
            delete tempTransaction.share;
        }
        // tags
        tempTransaction.promises = [];
        tempTransaction.tags = [];
        for (let tag of this.temptags) {
            if (this.dataService.getTagForId(tag.value)) {
                tempTransaction.tags.push(tag.value);
            } else {
                tempTransaction.promises.push(new Promise<void>((resolve, reject) => {
                    let newTag = new Tag();
                    newTag.name = tag.display;
                    this.dataService.addTag(newTag).then(tag => {
                        tempTransaction.tags.push(tag['id']);
                        this.tags = Array.from(this.dataService.tags.values());
                        resolve();
                    })
                }));
            }
        }

        return tempTransaction;
    }

    dateToStartOfCurrentMonth() {
        this.currentTransaction.date = this.dateService.getFirstDateInMonth(new Date());
        this.currentTransaction.date.setHours(6);
        this.currentTransaction.date.setMinutes(0);
        this.currentTransaction.date.setSeconds(0);
        this.currentTransaction.date.setMilliseconds(0);
    }

    dateToEndOfCurrentMonth() {
        this.currentTransaction.date = this.dateService.getLastDateInMonth(new Date());
        this.currentTransaction.date.setHours(20);
        this.currentTransaction.date.setMinutes(0);
        this.currentTransaction.date.setSeconds(0);
        this.currentTransaction.date.setMilliseconds(0);
    }

    dateToStartOfNextMonth() {
        this.currentTransaction.date = this.dateService.getFirstDateInMonth(this.dateService.getNextMonth(this.dateService.getFirstDateInMonth(new Date())));
        this.currentTransaction.date.setHours(6);
        this.currentTransaction.date.setMinutes(0);
        this.currentTransaction.date.setSeconds(0);
        this.currentTransaction.date.setMilliseconds(0);
    }

    dateToEndOfNextMonth() {
        this.currentTransaction.date = this.dateService.getLastDateInMonth(this.dateService.getNextMonth(this.dateService.getFirstDateInMonth(new Date())));
        this.currentTransaction.date.setHours(20);
        this.currentTransaction.date.setMinutes(0);
        this.currentTransaction.date.setSeconds(0);
        this.currentTransaction.date.setMilliseconds(0);
    }

    closeDialog() {
        if (this.dialog) {
            this.dialog.dismiss();

        }
        //cleanup
        this.dialog = null;
        this.error = null;
    }

    highlightLatestElement() {
        var currentDate = new Date();
        var transaction = null;
        this.transactions.some((tempTransaction, index) => {
            var transactionDate = new Date(this.transactions[index].date);
            if (transaction == null && transactionDate <= currentDate) {
                transaction = this.transactions[index];
                return true;
            } else {
                if (transactionDate <= currentDate && transactionDate > transaction.date) {
                    transaction = this.transactions[index];
                    return true;
                }
            }
            return false;
        })
        if (transaction) {
            this.latestTransactionId = transaction.id;
        } else {
            this.latestTransactionId = -1;
        }
    };

    statisticChanged() {
        this.showStatistics = !this.showStatistics;
    }

    setDateToCurrentMonth() {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = 1;
        var firstDateInMonth = new Date(year, month, 1);
        this.dateStart = this.getFormattedString(firstDateInMonth);
        var lastDateInMonth = new Date(year, month + 1, 0);
        this.dateEnd = this.getFormattedString(lastDateInMonth);
        this.filterChanged(this.dateStart, this.dateEnd, null, this.filtertext);
    }

    getFormattedString(date: Date): string {
        return this.datePipe.transform(date, 'dd.MM.yyyy');
    }

    getFormattedDate(input: string): Date {
        var date: Date = null;
        try {
            var parts = input.split(".");
            date = new Date(parseInt(parts[2], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[0], 10));
        } finally {
            // ignore catch
            return date;
        }
    }

    goToPreviousMonth() {
        var newDate = this.dateService.getPreviousMonth(this.firstDateInMonth);
        this.changeDateFilter(newDate);
    }

    goToCurrentMonth() {
        this.changeDateFilter(null);
    }

    goToNextMonth() {
        var newDate = this.dateService.getNextMonth(this.firstDateInMonth);
        this.changeDateFilter(newDate);
    }

    changeDateFilter(date: Date) {
        var firstDateInMonth = this.dateService.getFirstDateInMonth(date);
        var lastDateInMonth = this.dateService.getLastDateInMonth(date);

        this.dateStart = this.getFormattedString(firstDateInMonth);
        this.dateEnd = this.getFormattedString(lastDateInMonth);

        this.filterChanged(this.dateStart, this.dateEnd, null, this.filtertext);
        this.firstDateInMonth = firstDateInMonth;
    }
}
