import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class AuthGuard  {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {
    }

    canActivate() {
        if (this.authenticationService.hasToken()) {
                return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}