import { Component, OnInit, Input, ViewEncapsulation, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import * as d3 from 'd3';

@Component({
  selector: 'app-bilance',
  templateUrl: './bilance.component.html',
  styleUrls: ['./bilance.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BilanceComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() field: string;
  @Input() id: string;
  constructor(private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.redrawGraph();
  }

  private svg;
  private xMargin = 50;
  private yMargin = 30;
  private width = 1200 - (this.xMargin * 2);
  private height = 300 - (this.yMargin * 2);

  private redrawGraph() {
    const selector = "#" + this.id + " > figure#bilance";

    // remove old childs
    d3.select(selector)
      .selectAll("*")
      .remove();

    // append new svg
    this.svg = d3.select(selector)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 1200 300")
      .append("g")
      .attr("transform", "translate(" + this.xMargin + "," + this.yMargin + ")");

    this.drawBars(this.data);
  }

  private drawBars(data: any[]): void {
    // Create the X-axis band scale
    const xScale = d3.scaleBand()
      .range([0, this.width])
      .domain(data.map(d => d.label.slice(5, 8) + "/" + d.label.slice(2, 4)))
      .padding(0.2);

    const xAxis = d3.axisBottom(xScale)
      .tickSize(-this.height)
      .tickSizeOuter(0);

    // Draw the X-axis on the DOM
    this.svg.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .attr('class', 'axis-grid')
      .call(xAxis)
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    let yMin = Math.min.apply(null, data.map<Object>(v => v[this.field]));
    let yMax = Math.max.apply(null, data.map(v => v[this.field]));

    // Create the Y-axis band scale
    const yScale = d3.scaleLinear()
      .domain([yMin, yMax])
      .range([this.height, 0])
      .nice();

    const yAxis = d3.axisLeft(yScale)
      .tickSize(-this.width)
      .tickSizeOuter(0);

    yAxis.tickFormat(d => {
      return this.currencyPipe.transform(d / 100, "EUR", "symbol", "1.0-0");
    })

    // Draw the Y-axis on the DOM
    this.svg.append("g")
      .attr('class', 'axis-grid')
      .call(yAxis);

    // Create and fill the bars
    this.svg.selectAll("bars")
      .data(data)
      .enter()
      .append("rect")
      .merge(this.svg.selectAll("bars"))
      .attr("x", d => xScale(this.formatX(d.label)))
      .attr("y", d => yScale(Math.max(0, d[this.field])))
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => Math.abs(yScale(Math.max(0, yMin) - d[this.field]) - yScale(0)))
      .attr("fill", (d) => d[this.field] > 0 ? "#47a447" : "#d2322d")
      .attr("opacity", 0.5)
      .on("mouseenter", function () { d3.select(this).attr("opacity", 1); })
      .on("mouseleave", function () { d3.select(this).attr("opacity", 0.5) });

    this.svg.selectAll(".text")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "barLabel")
      .attr('x', (d) => xScale(this.formatX(d.label)) + xScale.bandwidth() / 2)
      .attr('y', (d) => yScale(d[this.field]) - 2)
      .attr('text-anchor', 'middle')
      .text(d => this.currencyPipe.transform(d[this.field] / 100, "EUR", "symbol", "1.0-0"));
  }

  private formatX(input: String) {
    return input.slice(5, 8) + "/" + input.slice(2, 4);
  }

}
