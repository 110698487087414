import { Component, Input } from '@angular/core';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-flexible-input',
  templateUrl: './flexible-input.component.html',
  styleUrls: ['./flexible-input.component.css']
})
export class FlexibleInputComponent {
  @Input()
  model: any;

  constructor(private homeComponent: HomeComponent) { }

  ngOnInit(): void { }

  dataChanged(newValue: any) {
    this.homeComponent.updateSetting(this.model.key, newValue);
  }

  checkboxDataChanged(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.homeComponent.updateSetting(this.model.key, checkbox.checked);
  }

}