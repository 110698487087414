import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';

import { StorageService } from '../_services/storage.service';

import { Account } from '../_models/account';
import { Category } from '../_models/category';
import { Group } from '../_models/group';
import { Note } from '../_models/note';
import { Person } from '../_models/person';
import { Tag } from '../_models/tag';
import { Transaction } from '../_models/transaction';

import { map } from 'rxjs/operators';

@Injectable()
export class NetworkService {
  constructor(private httpClient: HttpClient, private storageService: StorageService) {
    console.info('NetworkService constructor()');
  }

  authenticate(username: string, password: string) {
    return this.httpClient.post('/api/authenticate', JSON.stringify({ username: username, password: password }));
  }

  reauthenticate() {
    return this.httpClient.post('/api/reauthenticate', null, this.jwt());
  }

  import(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.httpClient
      .post('/api/import', formData, this.jwt())
  }

  export() {
    return this.httpClient.get<Response>('/api/export', this.jwt());
  }

  getAccounts() {
    return this.httpClient.get<Account[]>('/api/account', this.jwt());
  }

  getCategorys() {
    return this.httpClient.get<Category[]>('/api/category', this.jwt());
  }

  getGroups() {
    return this.httpClient.get<Group[]>('/api/group', this.jwt());
  }

  getTags() {
    return this.httpClient.get<Tag[]>('/api/tag', this.jwt());
  }

  getNotes() {
    return this.httpClient.get<Note[]>('/api/note', this.jwt());
  }

  getPersons() {
    return this.httpClient.get<Person[]>('/api/person', this.jwt());
  }

  getTransactions() {
    return this.httpClient.get<Transaction[]>('/api/transaction', this.jwt());
  }

  deleteTransaction(id: number) {
    return this.httpClient.delete('/api/transaction/' + id, this.jwt());
  }

  editTransaction(transaction) {
    return this.httpClient.put('/api/transaction/' + transaction.id, transaction, this.jwt());
  }

  addTransaction(transaction) {
    return this.httpClient.post<Transaction>('/api/transaction', transaction, this.jwt());
  }

  deleteAccount(id: number) {
    return this.httpClient.delete('/api/account/' + id, this.jwt());
  }

  editAccount(account) {
    return this.httpClient.put('/api/account/' + account.id, account, this.jwt());
  }

  addAccount(account) {
    return this.httpClient.post<Account>('/api/account', account, this.jwt());
  }

  deleteGroup(id: number) {
    return this.httpClient.delete('/api/group/' + id, this.jwt());
  }

  editGroup(group) {
    return this.httpClient.put('/api/group/' + group.id, group, this.jwt());
  }

  addGroup(group) {
    return this.httpClient.post<Group>('/api/group', group, this.jwt());
  }

  deleteCategory(id: number) {
    return this.httpClient.delete('/api/category/' + id, this.jwt());
  }

  editCategory(category) {
    return this.httpClient.put('/api/category/' + category.id, category, this.jwt());
  }

  addCategory(category) {
    return this.httpClient.post<Category>('/api/category', category, this.jwt());
  }

  deleteTag(id: number) {
    return this.httpClient.delete('/api/tag/' + id, this.jwt());
  }

  editTag(tag) {
    return this.httpClient.put('/api/tag/' + tag.id, tag, this.jwt());
  }

  addTag(tag) {
    return this.httpClient.post<Tag>('/api/tag', tag, this.jwt());
  }

  deletePerson(id: number) {
    return this.httpClient.delete('/api/person/' + id, this.jwt());
  }

  editPerson(person) {
    return this.httpClient.put('/api/person/' + person.id, person, this.jwt());
  }

  addPerson(person) {
    return this.httpClient.post<Person>('/api/person', person, this.jwt());
  }

  editNote(note) {
    return this.httpClient.put('api/note/' + note.id, note, this.jwt());
  }

  addNote(note) {
    return this.httpClient.post<Note>('api/note', note, this.jwt());
  }

  deleteNote(id) {
    return this.httpClient.delete('api/note/' + id, this.jwt());
  }

  getSetting() {
    return this.httpClient.get('/api/setting', this.jwt()).pipe(map(response => new Map(Object.entries(response))));
  }

  editSetting(setting: Map<string, any>) {
    // Map<string,any> must be converted to object
    let settingAsObject = Array.from(setting).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
    return this.httpClient.put('/api/setting', settingAsObject, this.jwt());
  }

  private jwt() {
    // create authorization header with jwt token
    let token = this.storageService.getToken();
    if (token) {
      const headers = new HttpHeaders({ 'Authorization': token });
      return { headers: headers };
    }
  }
}