<div>
  <div class="col align-self-center">
    <button type="button" class="btn btn-secondary btn-sm fa fa-plus float-left" (click)="openAddDialog()"></button>
  </div>
</div>
<div>
  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-container ngbNavItem *ngFor="let note of notes">
      <a ngbNavLink class="tab-title"><span>{{note.title}}</span><a><i (click)="openDeleteDialog(note)"
            class="fa fa-times" style="margin-left: 10px"></i></a></a>
      <ng-template ngbNavContent>
        <button (click)="editNote(note)" [disabled]="!note.changed" class="btn btn-success">Speichere Notiz!</button>
        <quill-editor [(ngModel)]="note.content" (onContentChanged)="contentChanged($event, note);"></quill-editor>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav"></div>
</div>
<ng-template #deleteNoteDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-footer">
    <button (click)="deleteNote()" class="btn btn-secondary">Löschen</button>
    <button (click)="closeDialog()" class="btn btn-secondary">Abbrechen</button>
  </div>
</ng-template>
<ng-template #addNoteDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3 row">
        <label class="col-sm-2 col-form-label">Titel:</label>
        <div class="col-sm-10">
          <input class="form-control" type="text" [(ngModel)]="noteTitle" name="description" />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="addNote()" class="btn btn-secondary">Hinzufügen</button>
    <button (click)="closeDialog()" class="btn btn-secondary">Abbrechen</button>
  </div>
</ng-template>