import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Person } from '../../_models/person';
import { DataService } from '../../_services/data.service';
import { EditableComponent } from './../editable/editable.component';

@Component({
    templateUrl: 'persons.component.html',
    host: { 'collision-id': 'PersonsComponent' }
})

export class PersonsComponent extends EditableComponent implements OnInit {

    datatypeName = "Person";
    persons: any[];

    constructor(protected dataService: DataService, protected modalService: NgbModal) {
        super(modalService);
    }

    ngOnInit() {
        console.info('PersonsComponent ngOnInit()');
        this.persons = this.updateAllData();
    }

    openNewDialog() {
        super.openCreateDialog(new Person);
    }

    updateAllData() {
        // load all persons
        let persons = Array.from(this.dataService.persons.values());
        // remove deleted
        persons = persons.filter(person => !person.deleted);
        // sort by date
        persons.sort((a, b) => (a.name > b.name ? 1 : -1));
        // set locally persons
        return persons;
    }

    editData() {
        let tempPerson = JSON.parse(JSON.stringify(this.currentData));
        if (tempPerson.id != null) {
            this.dataService.editPerson(tempPerson).then(result => {
                this.persons = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        } else {
            this.dataService.addPerson(tempPerson).then(result => {
                this.persons = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        }
    }

    deleteData() {
        this.dataService.deletePerson(this.currentData).then((result) => {
            this.persons = this.updateAllData();
            this.closeDialog();
        }, error => {
            this.error = error;
        });
    }
}