import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';

import { routing } from './app.routing';

// Components
import { AccountsComponent } from './_components/accounts/accounts.component';
import { AlertComponent } from './_components/alert/alert.component';
import { AmountComponent } from './_components/amount/amount.component';
import { CategorysComponent } from './_components/categorys/categorys.component';
import { DataComponent } from './_components/data/data.component';
import { FlexibleInputComponent } from './_components/flexible-input/flexible-input.component';
import { BilanceComponent } from './_components/graph/bilance/bilance.component';
import { GroupsComponent } from './_components/groups/groups.component';
import { HomeComponent, } from './_components/home/home.component';
import { LoginComponent } from './_components/login/login.component';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { NotesComponent } from './_components/notes/notes.component';
import { PersonsComponent } from './_components/persons/persons.component';
import { TagsComponent } from './_components/tags/tags.component';
import { ToolsComponent } from './_components/tools/tools.component';
import { TransactionsComponent } from './_components/transactions/transactions.component';
import { AppComponent } from './app.component';
// Guards
import { AuthGuard } from './_guards/auth.guard';
// Pipes
import { AccountsFilterAndSortPipe } from './_pipes/accountsfilterandsort.pipe';
// Services
import { InfoComponent } from './_components/info/info.component';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { DataService } from './_services/data.service';
import { NetworkService } from './_services/network.service';
import { StorageService } from './_services/storage.service';
import { StyleService } from './_services/style.service';

registerLocaleData(localeDE);

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        routing,
        InfiniteScrollModule,
        NgbModule,
        TagInputModule,
        BrowserAnimationsModule,
        QuillModule.forRoot()
    ],
    declarations: [
        AppComponent,
        AccountsComponent,
        AmountComponent,
        AlertComponent,
        AccountsFilterAndSortPipe,
        CategorysComponent,
        DataComponent,
        GroupsComponent,
        HomeComponent,
        InfoComponent,
        LoginComponent,
        NavbarComponent,
        NotesComponent,
        PersonsComponent,
        TagsComponent,
        TransactionsComponent,
        AmountComponent,
        BilanceComponent,
        ToolsComponent,
        FlexibleInputComponent
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        NetworkService,
        DataService,
        StorageService,
        StyleService,
        CurrencyPipe,
        DatePipe,
        { provide: LOCALE_ID, useValue: "de-DE" }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
