import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../_services/data.service';
import { EditableComponent } from './../editable/editable.component';

import { Account } from '../../_models/account';
@Component({
    templateUrl: 'accounts.component.html'
})

export class AccountsComponent extends EditableComponent implements OnInit {

    datatypeName = "Konto";
    accounts: any[];

    constructor(protected dataService: DataService, protected modalService: NgbModal) {
        super(modalService);
    }

    ngOnInit() {
        console.info('AccountsComponent ngOnInit()');
		this.accounts = this.updateAllData();
    }

    openNewDialog() {
        super.openCreateDialog(new Account);
    }

    updateAllData() {
        // load all accounts
        let accounts = Array.from(this.dataService.accounts.values());
        // remove deleted
        accounts = accounts.filter(account => !account.deleted);
        // sort
        accounts.sort((a, b) => this.dataService.sortAccount(a, b));
        // set locally accounts
        return accounts;
    }

    editData() {
        let tempAccount = JSON.parse(JSON.stringify(this.currentData));
        if (tempAccount.id != null) {
            this.dataService.editAccount(tempAccount).then(result => {
                this.accounts = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        } else {
            this.dataService.addAccount(tempAccount).then(result => {
                this.accounts = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        }
    }

    deleteData() {
        this.dataService.deleteAccount(this.currentData).then((result) => {
            this.accounts = this.updateAllData();
            this.closeDialog();
        }, error => {
            this.error = error;
        });
    }

    moveAccount(account: Account, direction: String) {
        account = this.dataService.getAccountForId(account.id);
        let foundIndex = -1;

        this.accounts.forEach((tempAccount, index) => {
            tempAccount = this.dataService.getAccountForId(tempAccount.id);
            if (account.id == tempAccount.id) {
                foundIndex = index;
            }
            if (tempAccount.position != index) {
                tempAccount.position = index;
                this.dataService.editAccount(tempAccount).then(() => {
                    this.updateAllData();
                })
            }
        });

        if (foundIndex != -1) {
            let newIndex = JSON.parse(JSON.stringify(foundIndex));
            if (direction == 'UP') {
                newIndex = newIndex - 1;
            } else if (direction == 'DOWN') {
                newIndex = newIndex + 1;
            }

            let switchedAccount = this.accounts[newIndex];
            if (switchedAccount != null && switchedAccount.id != account.id) {
                switchedAccount = this.dataService.getAccountForId(switchedAccount.id);
                switchedAccount.position = foundIndex;
                this.dataService.editAccount(switchedAccount).then(() => {
                    this.updateAllData();
                })
                account.position = newIndex;
                this.dataService.editAccount(account).then(() => {
                    this.updateAllData();
                })
            }
        }

        this.accounts = this.updateAllData();
    }
}