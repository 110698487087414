import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DataService } from '../../_services/data.service';
import { NetworkService } from '../../_services/network.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Note } from '../../_models/note';

@Component({
    templateUrl: 'notes.component.html'
})

export class NotesComponent implements OnInit {
    @ViewChild("deleteNoteDialog") private deleteNoteDialog: TemplateRef<any>;
    @ViewChild("addNoteDialog") private addNoteDialog: TemplateRef<any>;

    constructor(private dataService: DataService, private networkService: NetworkService, private modalService: NgbModal) { }
    notes: any[];

    //dialog handle
    private dialog: NgbModalRef;
    private note: any;
    private error: String;
    title: any;
    noteTitle: any;

    ngOnInit() {
        console.info('NotesComponent ngOnInit()');
        this.notes = JSON.parse(JSON.stringify(Array.from(this.dataService.notes.values())));
        this.notes.forEach((note) => {
            note.changed = false;
        })
    }

    contentChanged(event, note) {
        if ("user" == event.source) {
            note.changed = true;
            note.content = event.html;
        }
    }

    editNote(note) {
        this.dataService.editNote(note).then((result) => {
            note.changed = false;
        }, error => {
            //
        });
    }

    openDeleteDialog(note) {
        this.title = "Soll die Notiz '"+note.title+"' wirklich gelöscht werden?"
        this.note = note
        this.dialog = this.modalService.open(this.deleteNoteDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    openAddDialog() {
        this.title = "Notiz hinzufügen"
        this.dialog = this.modalService.open(this.addNoteDialog, { centered: true, backdrop: 'static', keyboard: false, size: 'lg' });
    }

    addNote(){
        let note = new Note();
        note.title = this.noteTitle;
        note.deleted = false;
        note.content = '';

        this.dataService.addNote(note).then((result) => {            
            this.notes.push(note);
            this.closeDialog();
        }, error => {
            //
        })
    }

    deleteNote(){
        this.dataService.deleteNote(this.note).then((result) => {
            // delete local note
            var i = this.notes.length
            while (i--) {
                if (this.notes[i].id == this.note.id) { 
                    this.notes.splice(i, 1);
                } 
            }

            this.closeDialog();
        }, error => {
            //
        })
    }

    closeDialog() {
        if (this.dialog) {
            this.dialog.dismiss();
        }
        //cleanup
        this.noteTitle = null;
        this.title = null;
        this.dialog = null;
        this.error = null;
    }
}