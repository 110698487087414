import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Tag } from '../../_models/tag';
import { DataService } from '../../_services/data.service';
import { EditableComponent } from './../editable/editable.component';

@Component({
    templateUrl: 'tags.component.html',
    host: { 'collision-id': 'TagsComponent' }
})

export class TagsComponent extends EditableComponent implements OnInit {

    datatypeName = "Tag";
    tags: any[];

    constructor(protected dataService: DataService, protected modalService: NgbModal) {
        super(modalService);
    }

    ngOnInit() {
        console.info('TagsComponent ngOnInit()');
        this.tags = this.updateAllData();
    }

    openNewDialog() {
        super.openCreateDialog(new Tag);
    }

    updateAllData() {
        // load all tags
        let tags = Array.from(this.dataService.tags.values());
        // remove deleted
        tags = tags.filter(tag => !tag.deleted);
        // sort by date
        tags.sort((a, b) => (a.name > b.name ? 1 : -1));
        // set locally tags
        return tags;
    }

    editData() {
        let tempTag = JSON.parse(JSON.stringify(this.currentData));
        if (tempTag.id != null) {
            this.dataService.editTag(tempTag).then(result => {
                this.tags = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        } else {
            this.dataService.addTag(tempTag).then(result => {
                this.tags = this.updateAllData();
                this.closeDialog();
            }, error => {
                this.error = error;
            })
        }
    }

    deleteData() {
        this.dataService.deleteTag(this.currentData).then((result) => {
            this.tags = this.updateAllData();
            this.closeDialog();
        }, error => {
            this.error = error;
        });
    }
}