import { Injectable } from '@angular/core';

import { DataService } from '../_services/data.service';
import { Transaction } from '../_models/transaction';
@Injectable()

export class StyleService {
    constructor(private dataService: DataService) {
        console.info('StyleService constructor()');
    }

    getStyleForTransaction(transaction: Transaction) {
        if (this.dataService.isIncome(transaction)) {
            return "positiveAmount";
        } else if (this.dataService.isOutcome(transaction)) {
            return "negativeAmount";
        }
        return "";
    }

    getStyleForAmount(amount: any) {
        if (amount < 0) {
            return "negativeAmount";
        } else if (amount > 0) {
            return "positiveAmount";
        }
        return "";
    }
}