import { Component, OnInit, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Account } from '../_models/account';

@Pipe({
  name: "filterAndSortById"
})
export class AccountsFilterAndSortPipe implements PipeTransform {
  transform(accounts: Array<Account>): Array<Account> {
    accounts = accounts.filter(account => account.intern);
    accounts.sort((a: Account, b: Account) => {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });    
    return accounts;
  }
}