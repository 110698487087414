import { RouterModule, Routes } from '@angular/router';

// Components
import { AccountsComponent } from './_components/accounts/accounts.component';
import { CategorysComponent } from './_components/categorys/categorys.component';
import { GroupsComponent } from './_components/groups/groups.component';
import { HomeComponent } from './_components/home/home.component';
import { InfoComponent } from './_components/info/info.component';
import { LoginComponent } from './_components/login/login.component';
import { NotesComponent } from './_components/notes/notes.component';
import { PersonsComponent } from './_components/persons/persons.component';
import { TagsComponent } from './_components/tags/tags.component';
import { ToolsComponent } from './_components/tools/tools.component';
import { TransactionsComponent } from './_components/transactions/transactions.component';
// Guards
import { AuthGuard } from './_guards/auth.guard';
// Services
import { DataService } from './_services/data.service';

const appRoutes: Routes = [
	{ path: 'login', component: LoginComponent },
	{ path: '', component: HomeComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'groups', component: GroupsComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'categorys', component: CategorysComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'accounts', component: AccountsComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'persons', component: PersonsComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'tags', component: TagsComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'notes', component: NotesComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'tools', component: ToolsComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: 'info', component: InfoComponent, canActivate: [AuthGuard], resolve: { initialData: DataService } },
	{ path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {});