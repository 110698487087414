import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from './_services/authentication.service';
import { DataService } from './_services/data.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {

	constructor(private authenticationService: AuthenticationService, private dataService: DataService) { }

	ngOnInit() {
		console.info('AppComponent ngOnInit()');
	}

	ngOnDestroy() {
		console.info('AppComponent ngOnDestroy()');
	}

	isLoggedIn() {
		return this.authenticationService.isLoggedIn();
	}

	isDataResolved() {
		return this.dataService.isDataResolved();
	}
}
