import { Share } from '../_models/share';

export class Transaction {
    id: number;
    date: Date;
    source: number;
    target: number;
    value: number;
    description: string;
    deleted: boolean;
    tags: Array<number>;
    category: number;
    active: boolean;
    share: Share;
}