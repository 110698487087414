import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { Account } from '../_models/account';
import { Category } from '../_models/category';
import { Group } from '../_models/group';
import { Note } from '../_models/note';
import { Person } from '../_models/person';
import { Tag } from '../_models/tag';
import { Transaction } from '../_models/transaction';

import { NetworkService } from '../_services/network.service';

@Injectable()
export class DataService  {
    accounts: Map<number, Account>;
    categorys: Map<number, Category>;
    groups: Map<number, Group>;
    notes: Map<number, Note>;
    persons: Map<number, Person>;
    tags: Map<number, Tag>;
    transactions: Map<number, Transaction>;
    setting: Map<string, any>;

    private dataResolved;
    private dataResolvedSubject;

    isDataResolved(): Observable<boolean> {
        return this.dataResolvedSubject.asObservable();
    }

    private setDataResolved(value) {
        this.dataResolved = value;
        this.dataResolvedSubject.next(this.dataResolved);
    }

    constructor(private router: Router, private networkService: NetworkService) {
        console.info('DataService constructor()');
        this.dataResolved = false;
        this.dataResolvedSubject = new BehaviorSubject<boolean>(this.dataResolved);
    }

    getAccountForId(id: number): Account {
        return this.accounts.get(id);
    }

    getCategoryForId(id: number): Category {
        return this.categorys.get(id);
    }

    getPersonForId(id: number): Person {
        return this.persons.get(id);
    }

    getTagForId(id: number): Tag {
        return this.tags.get(id);
    }

    getGroupForId(id: number): Tag {
        return this.groups.get(id);
    }

    getSetting(): Map<string, any> {
        return this.setting;
    }

    getCategorysForGroup(group: Group) {
        return Array.from(this.categorys.values()).filter(category => category.group == group.id).sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    }

    deleteTransaction(transaction: Transaction) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.deleteTransaction(transaction.id).subscribe(response => {
                this.transactions.delete(transaction.id);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editTransaction(transaction: Transaction) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editTransaction(transaction).subscribe(response => {
                this.transactions.set(transaction.id, transaction);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    addTransaction(transaction: Transaction) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.addTransaction(transaction).subscribe(response => {
                transaction.id = response.id;
                this.transactions.set(transaction.id, transaction);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    deleteAccount(account: Account) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.deleteAccount(account.id).subscribe(response => {
                account.deleted = true;
                this.accounts.set(account.id, account);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editAccount(account: Account) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editAccount(account).subscribe(response => {
                this.accounts.set(account.id, account);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    addAccount(account: Account) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.addAccount(account).subscribe(response => {
                account.id = response.id;
                this.accounts.set(account.id, account);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    deleteGroup(group: Group) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.deleteGroup(group.id).subscribe(response => {
                group.deleted = true;
                this.groups.set(group.id, group);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editGroup(group: Group) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editGroup(group).subscribe(response => {
                this.groups.set(group.id, group);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    addGroup(group: Group) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.addGroup(group).subscribe(response => {
                group.id = response.id;
                this.groups.set(group.id, group);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    deleteCategory(category: Category) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.deleteCategory(category.id).subscribe(response => {
                category.deleted = true;
                this.categorys.set(category.id, category);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editCategory(category: Category) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editCategory(category).subscribe(response => {
                this.categorys.set(category.id, category);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    addCategory(category: Category) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.addCategory(category).subscribe(response => {
                category.id = response.id;
                this.categorys.set(category.id, category);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    deleteTag(tag: Tag) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.deleteTag(tag.id).subscribe(response => {
                tag.deleted = true;
                this.tags.set(tag.id, tag);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editTag(tag: Tag) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editTag(tag).subscribe(response => {
                this.tags.set(tag.id, tag);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    addTag(tag: Tag) {
        return new Promise<Tag>((resolve, reject) => {
            this.networkService.addTag(tag).subscribe(response => {
                tag.id = response.id;
                this.tags.set(tag.id, tag);
                resolve(tag);
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }


    deletePerson(person: Person) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.deletePerson(person.id).subscribe(response => {
                person.deleted = true;
                this.persons.set(person.id, person);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editPerson(person: Person) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editPerson(person).subscribe(response => {
                this.persons.set(person.id, person);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    addPerson(person: Person) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.addPerson(person).subscribe(response => {
                person.id = response.id;
                this.persons.set(person.id, person);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editNote(note: Note) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editNote(note).subscribe(response => {
                this.notes.set(note.id, note);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    addNote(note: Note) {
        return new Promise<Note>((resolve, reject) => {
            this.networkService.addNote(note).subscribe(response => {
                note.id = response.id;
                this.notes.set(note.id, note);
                resolve(note);
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    deleteNote(note: Note) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.deleteNote(note.id).subscribe(response => {
                this.notes.delete(note.id);
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    editSetting(setting: any) {
        return new Promise<void>((resolve, reject) => {
            this.networkService.editSetting(setting).subscribe(() => {
                this.setting = setting;
                resolve();
            }, error => {
                reject(JSON.parse(error._body));
            })
        });
    }

    clear() {
        delete this.accounts;
        delete this.categorys;
        delete this.groups;
        delete this.notes;
        delete this.persons;
        delete this.tags;
        delete this.transactions;
        this.setDataResolved(false);
    }

    resolve(): Promise<any> {
        var promises = [];
        if (!this.dataResolved) {
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getAccounts().subscribe(accounts => {
                    this.accounts = new Map<number, Account>();
                    accounts.forEach(account => this.accounts.set(account.id, account));
                    resolve();
                }, () => reject())
            }));
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getCategorys().subscribe(categorys => {
                    this.categorys = new Map<number, Category>();
                    categorys.forEach(category => this.categorys.set(category.id, category));
                    resolve();
                }, () => reject())
            }));
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getGroups().subscribe(groups => {
                    this.groups = new Map<number, Group>();
                    groups.forEach(group => this.groups.set(group.id, group));
                    resolve();
                }, () => reject())
            }));
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getNotes().subscribe(notes => {
                    this.notes = new Map<number, Note>();
                    notes.forEach(note => this.notes.set(note.id, note));
                    resolve();
                }, () => reject())
            }));
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getPersons().subscribe(persons => {
                    this.persons = new Map<number, Person>();
                    persons.forEach(person => this.persons.set(person.id, person));
                    resolve();
                }, () => reject())
            }));
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getTags().subscribe(tags => {
                    this.tags = new Map<number, Tag>();
                    tags.forEach(tag => this.tags.set(tag.id, tag));
                    resolve();
                }, () => reject())
            }));
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getTransactions().subscribe(transactions => {
                    this.transactions = new Map<number, Transaction>();
                    transactions.forEach(transaction => this.transactions.set(transaction.id, transaction));
                    resolve();
                }, () => reject())
            }));
            promises.push(new Promise<void>((resolve, reject) => {
                this.networkService.getSetting().subscribe(setting => {
                    this.setting = setting;
                    resolve();
                }, () => reject())
            }));
        }
        var returnPromise = Promise.all(promises);
        if (promises.length > 0) {
            returnPromise.then(
                () => this.setDataResolved(true),
                () => this.router.navigate(['/login'])
            );
        }
        return returnPromise;
    }

    sortAccount(a: Account, b: Account) {
        return (a.position < b.position) ? -1 : (a.position > b.position) ? 1 : (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    }

    isIncome(transaction: Transaction) {
        return this.getBalance(transaction).income != 0;
    }

    isOutcome(transaction: Transaction) {
        return this.getBalance(transaction).outcome != 0;
    }

    getBalance(transaction: Transaction) {
        let balance = {
            income: 0,
            outcome: 0
        };

        if (this.getAccountForId(transaction.source).intern === false && this.getAccountForId(transaction.target).intern === true) {
            if (!transaction.share) {
                balance.income = transaction.value;
            } else if (transaction.share.value < transaction.value) {
                balance.income = transaction.value - transaction.share.value;
            } else if (transaction.share.value > transaction.value) {
                balance.outcome = transaction.share.value - transaction.value;
            }
        } else if (this.getAccountForId(transaction.source).intern === true && this.getAccountForId(transaction.target).intern === false) {
            if (!transaction.share) {
                balance.outcome = transaction.value;
            } else if (transaction.share.value < transaction.value) {
                balance.outcome = transaction.value - transaction.share.value;
            } else if (transaction.share.value > transaction.value) {
                balance.income = transaction.share.value - transaction.value;
            }
        }
        return balance;
    }
}